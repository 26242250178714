.container {}
.container.start {}

main .start {

  .intro { width: 100%; }
  .intro h1 { max-width: 45ch; text-align: center; margin: 45px auto 30px; }
  .intro .intro-points-list { margin: 20px auto; }
  .intro .intro-para { max-width: 50ch; text-align: center; font-weight: 300; margin: 0 auto; line-height: 35px; }
  .intro .intro-para.line-two { max-width: 60ch; }
  .intro .intro-para.line-two span { font-weight: 400; }
  .intro .para-container .para { font-size: 21px; line-height: 35px; font-weight: 300; }
  .intro .completion { flex-direction: column; margin: 45px auto 15px; font-weight: 400; }
  .intro .completion .time-estimate { margin: 15px auto; }


}


@media only screen and (max-width: 650px) {

  main .start .intro h1 { max-width: 24ch; margin: 15px auto 30px; }
  main .start .intro .intro-para { font-size: 16px; line-height: 30px; max-width: 36ch; }
  main .start .intro .intro-para.line-two { max-width: 35ch; }
  main .start .intro .completion { margin: 30px auto 0; }
  main .start .intro .para-container .para { font-size: 16px; line-height: 24px; margin: 0 auto; }

}

