@import url("https://use.typekit.net/fvk4nfp.css");
@import url("https://use.typekit.net/syk6jsc.css"); // Proxima Nova Font

// @import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;0,400;0,700;1,300;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;0,400;0,700;1,300;1,700&family=Montserrat:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

@font-face {
  font-family: "Butler"; font-weight: 900; font-style: normal;
  src: url("./Assets/Fonts/Butler-Black.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Black.woff") format("woff");
}

@font-face {
  font-family: "Butler"; font-weight: bold; font-style: normal;
  src: url("./Assets/Fonts/Butler-Bold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Bold.woff") format("woff");
}

@font-face {
  font-family: "Butler"; font-weight: 800; font-style: normal;
  src: url("./Assets/Fonts/Butler-ExtraBold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Butler"; font-weight: normal; font-style: normal;
  src: url("./Assets/Fonts/Butler.woff2") format("woff2"),
    url("./Assets/Fonts/Butler.woff") format("woff");
}

@font-face {
  font-family: "Silentina Movie"; font-weight: normal; font-style: normal;
  src: url("./Assets/Fonts/silentinamovie.ttf") format("truetype");
}



$color1: #EA1C22;

$color1opacity30: #009DF130;
$color1opacity42: #009DF142;
$color1opacity60: #009DF160;

$color2: #76CEFD;
$textcolor: #343333;

// $primaryfont: "Lato", sans-serif;
// $primaryfont: "Barlow", sans-serif;
// $primaryfont: "Proxima-Nova", sans-serif;
$primaryfont: "Lato", sans-serif;
$titlefont: "Silentina Movie", sans-serif;

body { background-color: #00252A; background-image: url('./Assets/Images/darkbg.png');
  background-size: contain; background-repeat: repeat; font-family: $primaryfont; color: $textcolor; margin: auto; overflow-x: hidden; }

.desktop { display: block; }
.mobile { display: none; }
.selection-form-list-item { text-transform: capitalize; }

header {
  & { background: #FFFFFF; }
  // .header-wrapper { display: flex; align-items: center; justify-content: space-between; height: 126px; max-width: 1100px; width: 100%; margin: 0 auto; }
  // .header-wrapper { margin: 0 auto; max-width: 1110px; height: min-content; display: flex; justify-content: space-between; align-items: center; padding: 3rem 1rem 1rem 1rem; }
  .header-wrapper { margin: 0 auto; max-width: 1110px; height: 126px; display: flex; align-items: center; }

  // .logo { 
  //   // & { position: absolute; left: 50px; cursor: default; }
  //   & { width: 262px; display: flex; align-items: center; justify-content: center; margin: 0 auto; }
  //   img { max-height: 60px; max-width: 200px; padding: 0 5px; }
  // }
  // .assessment-title { 
  //   & { flex: 1 1 auto; text-align: center; color: $textcolor; }
  //   .logo-text { font-size: 32px; margin: 0 auto; font-weight: 700; line-height: 43px; }
  //   .logo-subtext { font-size: 21px; margin: 0 auto; font-weight: 700; line-height: 25px; letter-spacing: 8px; }
  //   img { height: 29px; max-width: 433px; margin-top: 5px; } 
  // }
  // .bleat-logo { position: absolute; right: 50px; }
  // .logo-left { & { }
  //   a { }
  //   img { width: 240px; max-width: 192px; height: 62px;justify-content:flex-start; }
  // }

  .assessment-title { & { font-family: $titlefont; font-style: normal; font-weight: 700;width: 100%; color: #EA1C22; text-align: center; padding: 0; margin: 5px 0px; }
    .logo-text { font-family: $titlefont; font-size: 36px; line-height: 44px; color: #EA1C22; font-weight: 400; margin: 0 10px; }
    .logo-subtext { font-family: $titlefont; font-size: 21px; line-height: 25px; font-weight: 400; letter-spacing: 8px; text-transform: uppercase; margin: 0 auto; }
  }
  .logo-left { & { max-width: 170px; }
    img { max-width: 200px; height: 50px; width: auto; }
  }
  .logo-right { text-align: right; margin-bottom: 1rem; min-width: 200px; }
}


main {
  .logo-left-container{ & { position: absolute; height: 252px; width: 92px; left: 0px; top: calc(47vh - 200px); /* z-index: 1; */ }
    .logo-left { & {}
      img { height: 252px; width: 92px; }
    }
    img { height: 252px; width: 92px; }
}
  .assessment-progress-sections-container { & { margin: 0 auto 60px; text-align: center; position: relative; color: #FFFFFF; max-width: 1100px; }
    .main-sections-dots { & { position: relative; max-width: 1100px; height: 3px; background-color: #FFFFFF; margin: 0 auto; display: flex; justify-content: space-between; }
      .main-section { & { position: relative; }
        .main-section-dot { position: relative; bottom: 5px; margin: 0 auto; width: 10px; height: 10px; background-color: #FFFFFF; border: 2px solid black; border-radius: 50%; }
        .main-section-dot.finished { box-sizing: border-box; width: 25px; height: 25px; bottom: 12px; background-color: #FDBF04; display: flex; justify-content: center; align-items: center; }
        .main-section-dot.active { box-sizing: border-box; height: 25px; width: 25px; bottom: 12px; background-color: #FFFFFF; background-image: radial-gradient(white 40%, #FDBF04 40%); }
        .main-section-dot.unfinished { box-sizing: border-box; height: 25px; width: 25px; background-color: #E9E9E9; bottom: 12px; border: 2px solid black; }
      }
    }
    .main-sections-names { & { position: relative; max-width: 1100px; height: 3px; margin: 0 auto; display: flex; justify-content: space-between; }
      .main-section { & { position: relative; width: 10ch; }
        .main-section-name { position: relative; margin-top: 20px; text-align: center; text-transform: uppercase; }
        .main-section-name.finished { color: #FFFFFF; font-weight: 400; }
        .main-section-name.active { color: #FDBF04; font-weight: 700; }
        .main-section-name.unfinished { color: #FFFFFF; font-weight: 300; }
      }
    }
  }

  .container { margin: 30px auto; max-width: 1110px; border-radius: 10px; z-index: 1; }
  .loading { display: flex; justify-content: center; align-items: center; }
  
  // .container.start { width: 95%; }
  .container.start { width: 95%; 
    // background-image: url("../src/Assets/Images/homePage-Background.png"); background-size: cover; background-repeat: no-repeat; background-position: center; border-radius: 10px; 
  }
  .start { & { height: 578px; background: #ffffff; display: flex; justify-content: center; }
    .video { 
      // & { padding: 0.5rem; border-radius: 8px 0 0 8px; position: relative; width: auto; height: 100%; display: flex; justify-content: center; object-fit: cover; transition: all 0.25s; overflow: hidden; }
      // video { margin: 0 auto; }
      & { width: 50%; height: auto; display: flex; justify-content: center; position: relative; border-radius: 10px 0 0 10px; background-color: #00252A; object-fit: cover; transition: all 0.25s; overflow: hidden; }
      video { margin: 0px auto; position: relative; }
      .thumbnail { position: absolute; height: 100%; width: auto; overflow: hidden; }
      .playbtn { position: absolute; /* top: 85%; */ top: 87.5%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 45px; width: 223px; border-radius: 25px; }
      // .playbtn { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 144px; width: 144px; }
      .custom-controls { & { position: absolute; box-sizing: border-box; bottom: 10px; max-height: 45px; width: 100%; max-width: 530px; left: 50%; top: 87.5%; transform: translate(-50%, -50%); background-color: rgba(0, 0, 0, 0.5); padding: 5px; display: flex; align-items: center; justify-content: space-between; gap: 10px; z-index: 2; border-radius: 30px; }
        button { background-color: transparent; border: none; color: white; cursor: pointer; align-self: center; padding: 5px; display: flex; justify-content: center; align-items: center; }
        input[type='range'] { width: 85%; height: 2px; -webkit-appearance: none; }
        input[type=range]::-webkit-slider-runnable-track { border-radius: 30px; }
        input[type=range]:hover::-webkit-slider-runnable-track { background-color: #fff; cursor: pointer; opacity: 0.95; }
        input[type='range']::-webkit-slider-thumb { -webkit-appearance: none; background-color: $color1; border: none; width: 10px; height: 10px; border-radius: 50%; }
        span { color: white; font-size: 14px; min-width: 8ch; min-width: 70px; text-align: left; }

      }
    }
    .intro {
      & { box-sizing: border-box; width: 50%; padding: 0 2% 0 3%; display: flex; flex-direction: column; justify-content: center; transition: all 0.25s; }
      h1 { font-family: $primaryfont; font-weight: 700; font-size: 38px; color:#343333; line-height: 65px; width: auto; margin: 5px auto 5px 0; text-align: left; padding-top: 20px; max-width: 25ch; }
      .intro-para { font-weight: 600; font-size: 24px; line-height: 35px; margin: 10px auto; color: $textcolor; text-align: left; }
      h2 { margin: 0; margin-bottom: 0; font-weight: 300; font-style: normal; color: $textcolor; font-size: 24px; line-height: 27px; max-width: 100%; max-width: 27ch; }
      .intro-points-list { & { list-style: none; padding-left: 0px; margin: 20px auto 20px 0; font-weight: 300; font-style: normal; color: #343333; font-size: 24px; line-height: 36px; max-width: 100%; }
        li { margin: 0px auto; }
      }
      .completion { 
        & { width: 100%; display: flex; justify-content: space-between; align-items: center; text-align: center; margin: 11px auto 15px; color: #343333; font-weight: 700; font-size: 18px; line-height: 27px; }
        .cta { background-color: $color1; border-radius: 5px; color: #fff; text-decoration: none; width: 100%; max-width: 200px; height: 50px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 7px 10px; margin-left: 0; /* transition: 0.3s; */ }
        .cta:hover { background-color: #FFF; transition: 0.3s; color: $color1; border: 2px solid $color1; }
        .time-estimate {
          & { display: flex; justify-content: center; margin: 15px auto 15px 30px; }
          .clock-icon { width: 20px; height: 20px; margin-top: 3px; padding-right: 3px; }
        }
      }
      .para-container{ & {width: auto; text-align: center; padding-bottom: 15px; }
        .para { font-size: 14px; font-weight: normal; line-height: 24px; color: #343333; padding: 5px auto 30px; font-family: $primaryfont; }
      }
    }
    
    // .para-container {
    //   p { color: #5A5A5A; font-size: 24px; font-weight: 400; margin: 0.5rem; text-align: center; }
    // }
  //   .completion { & { display: flex; flex-direction: row; align-items: center; color: #4e4229; font-weight: 400; font-size: 18px; padding: 1.5rem 0rem; }
  //   }
  //   .btn-container{ & { padding-bottom: 2rem; }
  //   .cta { display: block; background: #348CFE; border-radius: 25px; color: #fff; text-decoration: none; width: 193px; text-align: center; font-family: $primaryfont; font-size: 20px; font-weight: 700; padding: 12px 0 12px; margin-left: 0; }
  //     .cta:hover { background: #fcb900; transition: 0.3s; color: #fff; }
  // }
  //     .time-estimate {
  //       & { display: flex; margin: 0; background-color: #FDE6A2; padding: 0.3rem 0.75rem 0.3rem 0.75rem; border-radius: 30px; }
  //       .clock-icon { width: 22px; height: 22px; }
  //       .img-container { padding-top: 0.1rem; padding-right: 0.25rem ; padding-left:0.5rem; }
  //     }
    
  }

  .pre-assessment { 
    & { color: $color2; max-width: 65ch; margin: 0px auto 15px; padding: 15px; font-family: $primaryfont; display: flex; flex-direction: column; justify-content: center; }
    h1 { font-size: 28px; font-weight: 600; line-height: 32px; text-align: center; }
    h2 { 
      & { font-weight: 400; text-align: center; }
      span { font-weight: 600; color: $color2; }
    }
    p { color: #696969; font-size: 18px; line-height: 25px; text-align: center; font-weight: 400; }
    .pre-assessment-subheader { font-weight: 600; text-align: center; font-size: 18px; }
    .explanation {
      & { display: flex; flex-direction: row; justify-content: center; align-items: center; font-size: 18px; }
      p { font-weight: 500; display: block; }
      .marker { box-sizing: border-box; flex: 0 0 30px; height: 30px; width: 100%; border-radius: 50%; background-color: white; margin: 10px 20px; }
      .red { border: 5px solid #FF2121; }
      .yellow { border: 5px solid #FDBF04; }
      .green { border: 5px solid #73B76F; }
    }
    .cta-link { text-decoration: none; margin: 30px auto 15px; }
    .cta { font-family: $primaryfont; color: #fff; display: block; margin: 0 auto; width: 240px; text-align: center; background-color: $color1; border-radius: 4px; font-weight: 700; font-size: 21px; letter-spacing: 1px; padding: 3px 15px; }
    .cta:hover { background-color: $color2; }
  }

  .progress-bar { & { display: flex; justify-content: center; margin: 0 auto; margin-top: 10px; width: 100%; grid-gap: 20px; }

    .bar-section { & {  }
      .progress-percentage { text-align: right; font-weight: 700; font-size: 21px; margin: 1rem 0 0.5rem 1rem; transition: all 0.4s linear; }
      .progress-grid { 
        display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
        .grid-square { width: 10px; height: 10px; margin: 2px; } 
      }
      .bar { height: 6px; margin: 0 5px 10px; }
      .section-title { width: 100%; text-align: center; font-family: $primaryfont; font-weight: 400; color: #343333; font-size: 21px; line-height: 25px; max-width: 14ch; margin: 0 auto; }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
    .custom-bar .MuiLinearProgress-bar { background-color: #00252A; }
    .sections { & {}
      .section-name { font-size: 18px; margin: 15px auto 5px; }
    }
  }
  .progress-percent-text { transition: 0.4s linear; font-family: $primaryfont; font-size: 18px; font-weight: 700; color: #00252A; margin-top: 10px; margin-bottom: 0; line-height: 25px; width: 3ch; max-width: calc(100% - 2ch); }
  .current-section { font-weight: 700; font-size: 21px; line-height: 25.2px; text-align: center; letter-spacing: 2px; text-transform: uppercase; color: #00252A; }

  .section-progress-top { /* max-width: 1050px; */ max-width: 750px; width: 95%; margin: 25px auto 5px; }

  .assessment {
    & { min-height: 490px; background: #fff; width: 96%; }
    .steps {
      & { margin: 0 auto 30px; width: 82.5%; min-height: 440px; }
      .spacer { height: 20px; }
      
      .progress-indicator { line-height: 21.6px; margin: 15px auto 30px; width: 100%; text-align: center; font-family: $primaryfont; font-weight: 400; color: #202945 ; font-size: 18px; }

      .question { & { line-height: 31px; display: flex; justify-content: center; align-items: flex-start; min-height: 120px; max-width: 751px; margin: 0 auto; text-align: center; font-family: $primaryfont; font-weight: 400; color: $textcolor; font-size: 24px; }
        p { margin: 15px auto; font-size: 24px; }
      }
      .radio-container {
        & { width: 86%; margin: 0 auto; display: flex; align-items: center; min-height: 130px; }
        .slider-labels {
          & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; display: flex; }
          .label {
            & { cursor: pointer; width: 20%; float: left; /* position: relative; */ }
            p { display: block; /* position: absolute; margin-left: -50%; */ margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: #ED1B24; }
        }
        .MuiRadio-root { margin: 5px auto; display: flex; width: 30px; height: 30px; }
      }
      .textarea-field { & { max-width: 540px; width: 85%; margin: 0 auto 0; background: #FFFFFF; border: 1px solid #979797; border-radius: 5px; padding: 6px 12px; }
        textarea { font-family: $primaryfont; font-size: 21px; }
      }
      .slider-container {
        & { width: 86%; margin: 30px auto 130px; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -4px; background: #B9B9B9; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; color: $textcolor; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; letter-spacing: 1px; line-height: 26px; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: #E31D1A; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: #E31D1A; width: calc(100% + 10px); margin-left: -5px; padding: 12.5px 0; position: absolute; top: 2px; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid #E31D1A; position: absolute; top: 0; }

        .custom-slider .MuiSlider-thumb { background-color: #E31D1A; padding: 9px; border: 3px solid #E31D1A; top: 0; }
        .custom-slider .MuiSlider-thumb:before { background-color: #E31D1A; border: 3px solid white; width: 4px; height: 4px; padding: 2px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px #E31D1A70; }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px #E31D1A70; }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: #E31D1A; top: -1px; }

        // .css-eglki6-MuiLinearProgress-root { background: #d8d8d8; }
        // .css-5xe99f-MuiLinearProgress-bar1 { background: $color1; }
        // .css-eg0mwd-MuiSlider-thumb { background: $color1; }
        // .css-eg0mwd-MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px #796BE170; }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px #796BE170; }
      }

      .slider-none-adjust { height: 35px; position: absolute; top: 2px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 30px auto 30px; padding-bottom: 30px; }
        button { color: #343333; font-weight: 400; font-size: 18px; font-family: $primaryfont; padding: 12.5px 25px; }
        .next-btn { background-color: none; color: black; font-weight: 600; }
      }
    }
  }

  .pre-additional-info { & { width: 97%; max-width: 1100px; padding: 15px 30px 30px; margin: 30px auto; box-sizing: border-box; background-color: white; text-align: center; }
    h1 { font-size: 24px; font-weight: 400; margin: 15px auto; padding: 30px 30px 10px; }
    p { font-size: 24px; font-weight: 300; max-width: 65ch; margin: 30px auto; }
    .radio { font-size: 24px; font-weight: 300; max-width: 65ch; margin: 30px auto; }
    .MuiTypography-root { font-size: 20px; font-weight: 300; max-width: 30ch; font-family: $primaryfont; }
    .Mui-checked { color: #F4D62B; }
    .Mui-checked + .MuiTypography-root { font-weight: 600; font-size: 19px; }
    .MuiFormControlLabel-root { width: 23ch; margin: 0 auto; }
  }
  .pre-additional-info, .pre-additional-questions-details, .assessment-additional {
    .step-buttons { & { display: flex; justify-content: space-around; align-items: center; max-width: 85%; margin: 15px auto; padding: 20px 15px; }
      .cta-prev { padding: 11px 25px; color: #182036; font-size: 18px; font-weight: 400; font-family: $primaryfont; }
      // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }
      .cta { display: block; background-color: #ED2028; border-radius: 5px; color: #fff; text-decoration: none; width: auto; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 7px 18px; margin-left: 0; transition: 0.3s;height:50px; }
      .cta.Mui-disabled { background-color: #D8D8D8; color: #979797; }
      .cta-active { background-color: #EA1C22; color: white;}
      .cta-disabled {background-color: #D8D8D8; color: #979797; }
      .cta:hover { background-color: #B0AC24; transition: 0.3s; color: #fff; }
      // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
      // .nextbtn:hover { background-color: $color2; }
      // .nextbtn.Mui-disabled { background: #C1C1C1; }
      .all-set-btn { font-size: 21px; /* padding: 7px 30px; */ background-color: #ED2028; color: #FFFFFF; }
    }
    .end-note{font-weight: 400; font-style: italic; font-size: 16px; line-height: 34px; text-align: center; color: #343333;}
  }
  .assessment-additional .step-buttons { max-width: 950px; justify-content: space-between; }


  .assessment-additional {
    & { min-height: 630px; background: #fff; border-radius: 10px; color: $textcolor; width: 95%; max-width: 900px; position: relative; }
    .radio-container .slider-labels { & { font-size: 21px; }
      p { margin: 5px auto; }
    }
    .radio-container {
      .label { & { font-weight: 400; font-size: 21px; color: #343333; justify-content: center; align-items: center; }
        .Mui-checked { fill : #EA1C22; color: #EA1C22; }
      }
      .label.active { font-weight: 400; }
    }
    .steps {
      & { margin: 0 auto 15px; width: 80%; min-height: 400px; }
      .spacer { height: 50px; }
      h1 { font-size: 21px; font-weight: 700; text-align: center; color: #343333; margin: 30px auto 15px; }

      .progress-indicator { margin: 25px auto 10px; width: 100%; text-align: center; font-family: $primaryfont; font-weight: 400; color: #343333; font-size: 18px; }

      .question { & { min-height: 90px !important; display: flex; justify-content: center; align-items: center; text-align: center; font-family: $primaryfont; font-weight: 400; font-size: 24px; color: #343333; max-width: 42ch; margin: 0 auto; padding: 0 0 15px; }
        p { margin: 5px auto; }
      }
      .textarea { min-height: 80px; }
      .textarea-field { max-width: 540px; width: 80%; margin: 18px auto; margin-bottom: 0; }
      .MuiInput-root { font-family: $primaryfont !important; font-size: 21px; border: 1px solid #979797; padding: 14px 18px; border-radius: 5px; }
      // .form-details-text { background-color: #fff; padding: 0 9px 22px; height: 90px; }
      .form-details-text { background-color: #fff; min-height: 150px; border-radius: 8px; }

      .slider-container {
        & { width: 86%; margin: 30px auto 120px; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #182036; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background-color: #B9B9B9; position: absolute; z-index: 9; }
          }
        }
        .slider-labels { & { width: 125%; font-weight: 400; font-size: 20px; color: #182036; }
          .label { & { cursor: pointer; width: 20%; float: left; position: relative; text-transform: capitalize; }
            p { /* display: block; */ position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; font-weight: 400; font-family: $primaryfont; font-size: 21px; line-height: 26px; }
          }
          .active.label p { font-weight: 700; color: #ABAD23; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: #ABAD23; padding: 10px 0; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid #ABAD23; }

        .custom-slider .MuiSlider-thumb { background-color: #ABAD23; padding: 9px; border: 3px solid #ABAD23; }
        .custom-slider .MuiSlider-thumb:before { background-color: #ABAD23; border: 3px solid white; width: 4px; height: 4px; padding: 2px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 1); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #D8D8D8; height: 3px; }
        .custom-bar .MuiLinearProgress-bar { background-color: #ABAD23; top: -2px; }

        // .css-eglki6-MuiLinearProgress-root { background: #d8d8d8; }
        // .css-5xe99f-MuiLinearProgress-bar1 { background: $color1; }
        // .css-eg0mwd-MuiSlider-thumb { background: $color1; }
        // .css-eg0mwd-MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
    }
    
    .step-buttons {
      & { display: flex; align-items: flex-start; justify-content: space-between; margin: 30px auto 5px; padding: 15px 0 5px; width: 80%; max-width: 540px; box-sizing: border-box; }
      button { color: #343333; font-weight: 400; font-size: 18px; font-family: $primaryfont; padding: 9px 15px; padding-left: 0; }
      .next-btn { color:#fff; background-color: #EA1C22; font-weight: 700; padding: 9px 25px; }
      .next-btn.mobile { color: #343333; background-color: #fff; }
      .next-btn.Mui-disabled { background-color: #D8D8D8; color: #979797; }
      .all-set-btn { color:#fff; background-color: #EA1C22; border-radius: 5px; padding: 9px 20px; }
      .skip-btn { background-color: #FFF; }
      .skip-btn:hover { background-color: #FFF; }
    }
  }

  .details, .pre-additional-questions-details {
    & { min-height: 630px; max-width: 1110px; width: 95%; background: #fff; }
    h1 { width: auto;font-weight: 700;font-size: 36px; line-height: 34px; text-align: center; margin: 0 auto; font-family: $primaryfont; font-size: 36px; color: $textcolor; padding: 32px 15px 0; }
    p { text-align: center; font-size: 21px; font-weight: 400; max-width: 45ch; line-height: 34px; color: $textcolor; padding: 0 15px; margin: 15px auto; }

    .form {
      & { display: flex; justify-content: center; flex-wrap: wrap; margin: 43px auto 0px auto; width: 65%; }
      .form-detail { 
        * { box-sizing: border-box; }
        & { position: relative; flex: 0 1 46%; max-width: 46%; display: block; margin-bottom: 20px; font-weight: 700; padding: 0 2%; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; line-height: 19px;color: #27282A; }
        .MuiInput-root { font-family: $primaryfont !important; font-size: 18px; font-weight: 400; }
        .MuiInput-input { height: 28px; font-weight: 600; }
        .MuiInput-input::placeholder { font-weight: 300; color: #868686; opacity: 1 !important; }
        .MuiSelect-select .first-menu { color: #fff; }
        
        // .form-details-text { background-color: #F4F4F4; padding: 15.6px 15px; padding: 10px 15px; max-width: 100%; }
        // .form-details-text { background-color: #F4F4F4; max-width: 100%; border-radius: 3px; }
        .form-details-text { background-color: #F4F4F4; max-width: 100%; border-radius: 3px; }
        .form-details-text .MuiInput-root { padding: 8px 15px; border-radius: 3px; border: 1px solid transparent; }
        .form-details-text .MuiInput-root.Mui-focused { border: 1px solid #3B3A3A; }
        
        
        .form-details-text.react-select div:nth-child(3) { border: none; }
        // .form-details-text.react-select div:nth-child(3) .react-select__control--is-focused {background-color: #FFFFFF; } 
        .form-details-text.react-select .react-select__value-container { padding: 6px 15px; }
        .form-details-text.react-select .react-select__value-container--has-value { background-color: white; border-radius: 3px; }
        .form-details-text.react-select .react-select__value-container--has-value ~ .react-select__indicators { background-color: white; border-radius: 3px; }
        .form-details-text.react-select .react-select__placeholder { white-space: nowrap; overflow-x: hidden; color: #868686; font-weight: 300; }
        .form-details-text.react-select { & { padding: 0; font-size: 18px; font-family: $primaryfont; font-weight: 600; }
          // div:first-child { padding-top: 6.25px; padding-bottom: 6.25px; }
        }

        .form-details-text input:focus::placeholder { color: transparent; font-weight: 300; }
        .form-details-text {
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
        // .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
        .error-text { position: initial; color: red; font-weight: 400; font-size: 13px; }
        .required-field { color: #EA1C22; }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
        }
        .react-select__control  {border-color: none; }
      }

      .job-details-section {
        .react-select__indicator { color: #21165A; }
      }
      
      .form-detail:last-child.school-name {
        flex: 0 1 100%; max-width: 100%; width: 100%;
      }
      .react-select__indicator-separator { width: 0px; }
    }

    .details-buttons { & { display: grid; grid-template-columns: 0.5fr 1fr 0.5fr; max-width: 80%; margin: 0 auto; padding-top: 3rem; padding-bottom: 3rem; } 
      .back-btn { & { align-self: center; } button { color: #444444; padding: 10px 15px; }
        button { font-family: $primaryfont; font-size: 18px; }
      }
      .cta-btn {
        & { margin: 0 auto; text-decoration: none; align-self: center; }
        a { text-decoration: none; }
        button.Mui-disabled { background-color: #979797; color: #FFFFFF; font-weight: 700 !important; }
        button { font-family: $primaryfont; display: block; background-color: #EA1C22; border-radius: 5px; color: #fff; text-decoration: none; width: 250px; height: 50px; text-align: center; font-size: 21px; font-weight: 700; line-height: 25px; text-transform: uppercase; padding: 7px 15px; margin-left: 0; transition: 0.3s}
        button:hover { background: #FFF; color: #EA1C22; border: 1px solid #EA1C22; transition: 0.3s; }
      .button-progress { margin: 4px 0 -2px; }
    }
    .cta-btn-active {
      & { margin: 0 auto; text-decoration: none; align-self: center; }
      a { text-decoration: none; }
      button.Mui-disabled { background-color: #D8D8D8; color: #FFFFFF; }
      button { display: block; background-color: #EA1C22; border-radius: 5px; color: #fff; text-decoration: none; width: 250px; height: 50px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; line-height: 25px; text-transform: uppercase; padding: 7px 15px; margin-left: 0; transition: 0.3s}
      button:hover { background-color: #FFF; color: #EA1C22; border: 2px solid #EA1C22; transition: 0.3s; }
    .button-progress { margin: 4px 0 -2px; }
  }
    }
  }
  .details .details-container { padding: 15px 15px 15px; }

  .results {
    & { min-height: 630px; background: #fff; color: $textcolor; width: 96%; margin: 60px auto 30px; }
    .spacer { height: 20px; }

    .selection-form {
      & { width: 50%; display: flex; align-items: center; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-size: 36px; font-weight: 400; }
      .form-detail {
        & { flex: 0 1 100%; }
        .expand-icon { cursor: pointer; position: absolute; right: -50px; top: 10px; height: 50px; width: 50px; color: #755075; }
      }
      .form-text { flex: 0 1 100%; }
      .form-details-text { 
        & { background-color: #fff; border: 0px solid #efefef; border-bottom: 4px solid #755075; padding: 3px 7px; max-width: 90%; }
        .MuiInput-root { font-size: 36px; font-family: $primaryfont; text-transform: capitalize; }
      }
    }

    h1 { width: auto; text-align: center; margin: 0 auto; font-family: $primaryfont; font-size: 30px; line-height: 38px; color: $textcolor; padding: 45px 20px 30px; max-width: 50ch;
      .user-name { text-transform: capitalize; }
    }
    
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: $color1; font-size: 18px; font-weight: 900; font-family: $primaryfont; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: $primaryfont; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      // background-image: linear-gradient(to right, #4298B5, #1FA099 62%, #2A9828 100%); 
      background-image: linear-gradient(to right, #cc2c24 25%, #E98526 50%, #FFCA27 75%, #4E9A51 100%);
    }
    .progress-opacity-filter { position: relative; display: flex; max-width: 700px; height: 26px; bottom: -26px; z-index: 1; box-sizing: border-box; margin: 0 auto; }
    .si_ass_progress .progress-line { z-index: 1; width: 2px; height: 26px; content: ' '; background-color: transparent; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $textcolor; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { z-index: 2; background: #00252A; border-radius: 8px; padding: 2px 5px; display: block; position: absolute; font-size: 27px; font-weight: 500; color: #ffffff; bottom: 55px; left: 0; margin-left: -30px; min-width: 45px; text-align: center; }
    .si_ass_progress .score-arrow { z-index: 1; width: 20px; height: 20px; content: ' '; background: $textcolor; left: 0; bottom: 50px; position: absolute; margin-left: -10px; transform: rotate(-45deg); }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }

    .pre-score-header { padding: 30px 15px 1.5rem; text-align: center; color: #4A4A4A; font-family: $primaryfont; font-size: 24px; font-weight: 600; margin: auto 0; }
    
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
    }
    
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { padding-right: 0; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered { & { width: 96%; max-width: 840px; background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { text-transform: uppercase; background-color: #755075; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 400; font-size: 18px; color: $textcolor; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 600; text-transform: uppercase; font-size: 15px; }
        p { margin: 0; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }
  }

  .invite {
    & { min-height: 380px; width: 97%; max-width: 956px; padding-bottom: 30px; background: #fff; margin-top: 50px; }
    h1 {font-weight: 700; line-height: 43px; width: 59.9%; text-align: center; margin: 0 auto 18px; font-family: $primaryfont; font-size: 36px; color: #27282A; padding: 48px 15px 0; }
    p {text-align: center; margin: 5px auto 30px; padding: 0 10px; font-size: 21px;  font-weight: 400; line-height:25px; color: #202020; max-width: 50ch; }
    p:nth-child(3) { margin-bottom: 35px; }
    .form {
      & { position: relative; display: flex; justify-content: space-around; flex-wrap: wrap; margin: 5px auto; width: 60%; }
      .invite-number { position: absolute; left: -25px; top: 62px; font-weight: bold; color: #C2C2C2; }
      .form-detail {
        & { flex: 0 1 46%; display: block; margin-bottom: 5px; font-weight: 700; }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; color: #27282A; }
        .input-title { margin: 10px 0; }
        .MuiInput-root { font-family: $primaryfont !important; font-size: 18px; font-weight: 400; }
        .invite-field { font-family: $primaryfont !important; background-color: #F4F4F4; }
        .invite-field .MuiInput-root { border: 1px solid transparent; padding: 5px 10px; border-radius: 3px; }
        .invite-field .MuiInput-root.Mui-focused { border: 1px solid #435B63; }
        .invite-field::placeholder { font-family: $primaryfont !important; font-size: 18px !important; color: #868686; font-weight: 300; }
        .invite-field .MuiInput-input { font-weight: 600; font-size: 21px; color: #343333; line-height: 26px; }
        .invite-field input::placeholder { color: #868686; font-weight: 300; opacity: 1; }
        .invite-field input:focus::placeholder { color: transparent; }
        .error-text { margin: 5px auto; color: red; font-weight: 400; font-size: 13px; }
      }      
      .delete-btn {
        & { position: absolute; right: -40px; top: 52px; top: 60px; }
        .delete-icon { color: #D5D5D5; font-size: 24px; cursor: pointer; }
      }
    }

    .invite-another-person {
      & { margin: 0 auto; width: 55%; position: relative; height: 5px; }
      .invite-another { & { display: flex; align-items: center; justify-content: center; width: 60px; background-color: #755075; position: absolute; top: -63px; cursor: pointer; right: -170px; border-radius: 5px; text-align: center; padding: 4px 6px; color: white; width: 74px; height: 32px; transition: 300ms; }
        .add-btn { font-size: 18px; padding: 5px 5px; color: white; font-weight: 700; font-family: $primaryfont; text-transform: uppercase; line-height: 22px; background-color: transparent; }
        .invite-another-icon { vertical-align: sub; font-size: 24px; }
      }
      .invite-another:hover { background:#343333;} 
    }
    .invite-notification-settings { & { margin: 0 auto; width: 58%; text-align: left; }
      .notification-checkbox .MuiFormControlLabel-label { font-family: $primaryfont; font-size: 18px; }
    }
    .buttons-container { & { display: flex; justify-content: space-evenly; align-items: center; margin: 15px auto; }
      .add-btn { background-color: #201D1D; border-radius: 5px; text-align: center; padding: 4px 6px; color: white; font-size: 18px; padding: 5px 5px; color: white; font-weight: 700; font-family: $primaryfont; text-transform: uppercase; line-height: 22px; }
      .add-btn:hover{ background-color: #FFF; color: #EA1C22; border: 1px solid #EA1C22; }
    }

    .cta-btn {
      & { display: block; margin: 30px auto 50px; text-decoration: none; width: 185px; height: 50px; background-color: #EA1C22; color: #fff; line-height: 25px; font-family: $primaryfont; border-radius: 5px;font-weight: 700; font-size: 21px; padding: 6px 0; text-align: center; text-transform: uppercase; }
      &.Mui-disabled { background-color: lightgray; }
      &:hover { background-color: #FFF; transition: 0.3s; color: #EA1C22; border: 1px solid #EA1C22; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .thank-you {
    & { min-height: 380px; width: 95%; max-width: 1120px ;padding-bottom: 30px; background: #fff; margin-top: 40px; }
    .tick { & { text-align: center; padding: 60px 0 0;border-radius: 8px; }
      img { width: 57px; height: 57px; }
    }
    h1 { width: auto; text-align: center; margin: 2px auto; font-family: $primaryfont; font-size: 36px; font-weight: 700; line-height: 43px; color: #27282A; padding: 28px 15px 28px; max-width: 914px; }
    // p { width: 65%; margin: 0 auto; text-align: center; font-size: 21px; color: #202020; }
    p { width: 44ch; margin: 0 auto; text-align: center; color: #000000; font-family: $primaryfont; font-size: 22px;font-weight: 500; line-height: 1.46em; }
    .back-btn { 
      & { text-align: center; margin: 2px auto; }
      a { border-bottom: 2px solid #EA1C22; text-transform: uppercase; font-weight: 700; cursor: pointer; text-decoration: none; color: #202020; font-size: 15px; line-height: 17px; font-family: Helvetica, sans-serif; }
    }
  }

  .invite-container {
    & { margin: 50px auto 15px; padding: 30px 30px 50px; font-family: $primaryfont; font-size: 21px; line-height: 32px; color: #343333; background-color: #FFFFFF; text-align: center; width: 96%; max-width: 1100px; box-sizing: border-box; border-radius: 10px; }
    h3 { font-weight: 700; font-size: 36px; line-height: 1.2; margin: 15px auto; }
    p { max-width: 72ch; margin: 1rem auto; }
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 45px 0 30px; max-width: 1400px; margin: 0 auto; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; max-width: 23ch; margin: 0 auto; line-height: 30px; }
        span { color: #343333; font-weight: 700; }
        img { height: 75px; }
      }
    }
    .cta-btn {
      & { display: inline-block; background-color: #EA1C22; margin: 30px auto 0; color: #fff; padding: 0; border-radius: 5px; width: 100%; max-width: 280px; }
      &:hover { background-color: #FFF; transition: 0.3s; color: #EA1C22; border: 1px solid #EA1C22; }
      a { display: block; text-decoration: none; color: #fff; }
      button { background-color: #EA1C22; width: 100%; max-width: 100%; display: inline-block; padding: 5px 25px; color: #fff; font-family: $primaryfont; font-size: 21px; font-weight: 700; height: 50px; border-radius: 5px; }
      button:hover { background-color: #FFF; transition: 0.3s; color: #EA1C22; border: 1px solid #EA1C22; }
    }
  }

  .team-container {
    & { max-width: 90%; width: auto; border-radius: 10px; margin: 50px auto; font-family: $primaryfont; font-size: 24px; line-height: 36px; color: #ffffff; text-align: center; font-weight: 400; }
    h3 { line-height: 42px; font-size: 36px; color: #ffffff; }
    .cta-btn {
      & { width: 220px; background: $color1; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: $primaryfont; font-size: 18px; font-weight: bold; }
    }
  }
 
  .individual-group {
    & { max-width: 400px; width:376px; margin: 30px auto 45px; display: flex; height: 49px; background-color: #FFF; border-radius: 5px; }
    button { cursor: pointer; flex: 0 1 50%; border: 0px; background: white; color: #1F2A44; font-family: $primaryfont; font-size: 18px; font-weight: 600; padding: 10px 0; text-align: center; border: 2px solid #FFF; }
    
    .left { border-top-left-radius: 5px; border-bottom-left-radius: 5px; font-weight: 400; color: #000;transition: all  ease-in-out 0.2s; }
    .left:hover { /* background-color: #435B63; color: #ffffff; */ transition: ease-in-out 0.2s; border-color: #EA1C22; border-radius: 5px; }
    .right { border-top-right-radius: 5px; border-bottom-right-radius: 5px; font-weight: 400; color: #000; transition: all  ease-in-out 0.2s;background-color: #FFFFFF; }
    .right:hover { /* background-color: #435B63; color: #ffffff; */ transition: ease-in-out 0.2s; border-color: #EA1C22; border-radius: 5px; }

    .active { background-color: #EA1C22; color: #ffffff; font-weight: 600; border-color: #EA1C22; border-radius: 5px; }
  }

  .toggle-individual-group { display: none; }

  .dashboard {
    & { min-height: 630px; background-color: #fff; width: 96%; }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: $primaryfont; font-size: 30px;font-weight: 700;line-height: 38px; color: $textcolor; padding: 45px 30px 30px; max-width: 65ch; }
    
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: $primaryfont; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: $primaryfont; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 700px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%);
      // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
    }
    .progress-opacity-filter { position: relative; display: flex; max-width: 700px; height: 26px; bottom: -26px; z-index: 1; box-sizing: border-box; margin: 0 auto; }
    .si_ass_progress .progress-line { z-index: 1; width: 2px; height: 26px; content: ' '; background-color: transparent; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -58px; left: -100px; width: 200px; height: 30px;
      color: #fdbf04; font-size: 16px; font-weight: 600; font-family: $primaryfont;  
    }
    // .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: 700;line-height: 36px; color:  #22223B; bottom: 35px; left: 0; margin-left: -20px; }
    .si_ass_progress .msi_score { z-index: 2; background: #00252A; border-radius: 8px; padding: 2px 5px; display: block; position: absolute; font-size: 27px; font-weight: 500; color: #ffffff; bottom: 57px; left: 0; margin-left: -30px; min-width: 45px; text-align: center; }
    .si_ass_progress .score-arrow { z-index: 1; width: 20px; height: 20px; content: ' '; background: $textcolor; left: 0; bottom: 51px; position: absolute; margin-left: -10px; transform: rotate(-45deg); }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
    
    .pervalue { max-width: 700px; margin: -68px auto; display: flex; clear: both; color: $textcolor; font-size: 16px; }
    // .pervalue h4.nimp:after { content: '70%'; position: absolute; right: -19px; }
    // .pervalue h4.mexpe:after { content: '80%'; position: absolute; right: -19px; }
    // .pervalue h4.eexpe:after { content: '95%'; position: absolute; right: -19px; }
    
    // .result-sections { 
    //   & { width: 95%; max-width: 800px; margin: 50px auto; margin-bottom: 0; display: flex; justify-content: center; flex-wrap: wrap; }
    //   .score-section { 
    //     & { flex: 0 1 25%; text-align: center; font-weight: 700; font-size: 18px; margin: 10px auto; }
    //     .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 6px; }
    //     .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
    //   }
    // }

    .pre-score-header { padding: 30px 15px 1.5rem; text-align: center; color: #4A4A4A; font-family: $primaryfont; font-size: 24px; font-weight: 600; margin: auto 0; }
    // .result-text { text-align: center; font-size: 24px; margin: 20px auto; max-width: 65ch; background-color: #FFFFFF; padding: 5px 30px; border-radius: 10px; color: #121212; }
    
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
    }
    

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; }
    .custom-bar .MuiLinearProgress-bar { background-color: $color1; }

    table { & { font-family: $primaryfont; border-collapse: collapse; width: 90%; max-width: 840px; margin: 0px auto 60px; font-size: 18px; font-weight: 400; color: #4A4A4A; }
      tr td:first-child { font-weight: 700; }
      tr:nth-child(even) { background-color: #F4F4F4; }
      td, th { border: 1px #dddddd; text-align: left; padding: 8px; }
      thead th { font-size: 16px; line-height: 17px; font-weight: 700; color: #373737; }
      th.center-cell:last-child, td.center-cell:last-child { font-weight: 700; color: #4A4A4A; }
      .center-cell { text-align: center; text-transform: capitalize;line-height: 17px; }
      .medium { line-height: 22px; font-style: normal; color: #4A4A4A; text-transform: capitalize; }
      .hash { width: 13px; }
      .avg { text-align: center; }
      .header { border-style: none; border-bottom: 3px solid #755075; }
      .footer { & { border-style: none; border-top: 3px solid #755075; color: #352E6C; }
        th.center-cell { color: #000000; }
        .church-average { font-size: 16px; color: #373737; font-weight: 700;line-height: 17px; }
      }
    }

    hr { width: 96%; color: #979797; }
 
    h2 { font-family: $primaryfont; font-size: 24px; font-weight: 700; color: #27282A;line-height: 38px; margin-top: 50px; text-align: center; }
    .most-answered {
      & { width: 96%; max-width: calc(840px); background: #FBFBFB; margin: 30px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 10px; overflow: hidden; }
      // .green { background-color: #73B76F; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .green { background-color: #73B76F; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 2px 2px; }
      // .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 2px 2px;} 
      .most-answered-detail { font-weight: 400; font-size: 18px; line-height: 22px; color: #000000; padding: 10px 30px; width: calc(100% - 60px);font-family: $primaryfont; }
      .most-answered-detail:nth-child(even) { background-color: #f4f4f4; }
    }

    .alignment { 
      & { width: 96%; max-width: 840px; margin: 0 auto 30px; background-color: $color1; margin: 50px auto 0; border-radius: 10px; }
      .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 150px; background-color: #D8D8D8; border-radius: 10px; }
      .tab-buttons { 
        & { display: flex; width: 100%; margin: 0 auto; justify-content: center; background-color: #D8D8D8; border-radius: 10px 10px 0px 0px; padding: 10px 8px 0; box-sizing: border-box; }
        button { margin: 3px 2px 0; font-family: $primaryfont; color: #585252; font-size: 18px; border-radius: 9px 9px 0px 0px; font-weight: 400;line-height: 22px; text-transform: uppercase; text-align: center; padding: 15px 20px; background: none; border: 0px; min-width: 110px; cursor: pointer; transition: all ease-in-out 0.2s; }
        button:hover { color: #FFFFFF; background-color: #1E1E1E; transition: all ease-in-out 0.2s; }
        .active { color: #FFFFFF; background-color: #755075; font-weight: 700;font-size: 18px;line-height: 22px;}
      }
      .tabs-content { flex-grow: 1; background-color: #755075; padding: 10px; border-radius: 8px; }

      .content { 
        & { width: 100%; display: none; }
        .align-details {
          & { max-width: 99%; background: #FBFBFB; margin: 10px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; text-align: left; border-radius: 10px; overflow: hidden;}
          // & { max-width: 99%; background: #FBFBFB; margin: 10px auto; padding: 0px 0% 0px; font-family: $primaryfont; font-size: 18px; text-align: left; border-radius: 10px; overflow: hidden; }
          // .green { background-color: #73B76F; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 0 0;}
          .green-container { display: flex; justify-content: space-between; background-color: #73B76F; cursor: pointer; align-items: center; }
          .green { color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 9.71539px 9.71539px 1.94308px 1.94308px; line-height: 21px; }
          .celebrateCurrentAreabtn { color: #FFFFFF; padding-right: 17px; }
          .red-container { display: flex; justify-content: space-between; background-color: #FF6645; cursor: pointer; align-items: center; }
          .red {color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius:9.71539px 9.71539px 1.94308px 1.94308px; line-height: 21px;}
          .analyzePotentialAreabtn { color: #FFFFFF; padding-right: 17px; }
          // .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-radius:9.71539px 9.71539px 1.94308px 1.94308px; line-height: 21px;}
          // .red { background-color: #FF6645; color: #FFFFFF; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px;}
          .answered-detail { 
            & { display: flex; flex-wrap: wrap; align-items: flex-start; font-weight: 400; font-size: 18px; color: $textcolor; padding: 10px 15px; width: calc(100% - 30px); }
            .answer { flex:-1 -1 200px; text-align: right; font-weight: 600; line-height: 18px; font-size: 16px; text-transform: uppercase; }
            .alignright { flex: 1 1 200px; }
            .true { color: #73B76F; }
            .neutral { color: #FDBF04; }
            .false { color: #FF6645; }
            .sagree { color: #2B7A27; }
            .sdisagree { color: #EA1C22; }
            p { flex: 0 1 93%; font-weight: 400; line-height: 24px; font-size: 16px; margin: 2px auto; }
            .more-toggle { display: block; flex: 0 1 5%; cursor: pointer; color: #ED8F14; min-width: 24px; width: 24px; display: flex; }
            .more-details { 
              & { display: none; width: 100%;  padding: 5px 2%; border-left: 3px solid #ED8F14; font-size: 14px; margin-top: 1rem; margin-left: 5%; }
              .more-row { 
                & { display: flex; align-items: center; }
                p { min-width: 14ch; max-width: 20%; padding: 5px; margin: 0;font-weight: 400; font-size: 16px; line-height: 18px; text-transform: capitalize; }
                .more-answer { text-transform: uppercase; font-weight: bold; }
              }
            }
          }
          .no-wrap { flex-wrap: nowrap; }
          .answered-detail { font-size: 16px; line-height: 24px; font-weight: 400; align-items: center; }
          .answered-detail:nth-child(even) { background-color: #f4f4f4; }
        }
        
      }
      .active-content { display: block; }
    }

    .mobile_scores {
      & { display: none; width: 90%; margin: 0 auto; }
      .individual {
        h2 { font-size: 18px; font-weight: 700; }
        & { font-size: 21px; font-weight: 400; border-bottom: 2px solid #755075; }
        .row { 
          & { height: 40px; display: flex; align-items: center; border-top: 2px solid #755075; }
          &:last-of-type { border-bottom: 2px solid #755075; }
          .number { flex: 0 1 30px; font-size: 18px; font-weight: 700; }
          .name { flex: 1 1 auto; font-weight: 700; }
          .percent { font-size: 18px; margin-right: 10px; color: #343333; font-weight: 700; }
          .toggle { padding-top: 6px; color: #755075; }
        }
        .scores { 
          & { font-size: 18px; border-top: 2px solid #755075; }
          .section_rows { 
            & { display: flex; padding: 12px 16px; }
            &:nth-of-type(even) { background-color: #F4F4F4; }
            .section { flex: 1 1 auto; font-weight: 700; }
            .section_score { flex: 0 1 auto; font-weight: 400; }
          }
        }
      }
    }
  }

  .dashboard-qa-container { 
    & { background-color: #F4F4F4; padding: 5px 15px; display: flex; justify-content: space-between; align-items: center; min-height: 45px; font-family: $primaryfont; }
    &:nth-child(odd) { background-color: #FBFBFB; }
    .dashboard-question { margin: 2px auto 2px 0; font-size: 16px; line-height: 21px; color: #27282A; text-align: left; width: 75%; }
    .dashboard-answer { margin: 2px 0 2px auto; font-size: 16px; line-height: 21px; font-weight: 700; text-transform: uppercase; text-align: right; }
  }

  .results, .dashboard {

    .progress-bar { & { margin: 60px auto 5px; max-width: 90%; display: block; }}
    .si_pro_header { max-width: 700px; margin: 10px auto 0; display: flex; clear: both; position: relative; bottom: 88px; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color:  $textcolor; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.progress-20-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.progress-40-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.progress-50-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.progress-60-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4.progress-80-percent:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: -2px; }
    .si_pro_header h4 span { color: $color1; line-height: 35px; font-size: 16px; }

    .pervalue h4 { position: relative; }
    .pervalue { max-width: 700px; margin: -68px auto; margin: 0 auto; display: flex; clear: both; color: #4A4A4A; font-size: 15px; position: relative; /* top: -20px; */ bottom: 70px; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.progress-0-percent:after { content: "0%"; position: absolute; top: 2px; right: -19px; font-weight: 800;}
    .pervalue h4.progress-20-percent:after { content: "20%"; position: absolute; top: 2px; right: -19px;  font-weight: 800;}
    .pervalue h4.progress-40-percent:after { content: "40%"; position: absolute; top: 2px; right: -19px;  font-weight: 800;}
    .pervalue h4.progress-50-percent:after { content: "50%"; position: absolute; top: 2px; right: -19px;  font-weight: 800;}
    .pervalue h4.progress-60-percent:after { content: "60%"; position: absolute; top: 2px; right: -19px; font-weight: 800;}
    .pervalue h4.progress-80-percent:after { content: "80%"; position: absolute; top: 2px; right: -19px; font-weight: 800;}
    .pervalue h4.progress-100-percent:after { content: "100%"; position: absolute; top: 2px; right: -12px; font-weight: 800;}
    // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
    .pervalue-text { & { position: relative; display: flex; margin: 0 auto; width: 100%; max-width: 700px; bottom: 70px; }
      p { font-family: $primaryfont; display: inline-block; text-align: center; margin: 0 auto; top: 12px; font-size: 17px; vertical-align: top; color: #575757; line-height: 18px; }
    }

    // .result-text { text-align: center; font-size: 18px; font-weight: 400; line-height: 28px; margin: 5px auto 15px; max-width: 48ch; background-color: #EEEEEE; padding: 25px 30px; border-radius: 10px; color: #121212; }
    .result-text { font-size: 18px; font-weight: 400; line-height: 28px; margin: 5px auto 15px; max-width: 81ch; background-color: #EEEEEE; padding: 20px 30px; border-radius: 10px; color: #121212; width: 95%; box-sizing: border-box; }
    .result-text p { padding-top: 0; margin-block-start: 0; margin: 10px auto; }

    .section-bottom-text {
      & { transition: 250ms ease; position: relative; border: 3px solid #EEEEEE; font-size: 18px; font-weight: 400; line-height: 28px; margin: 5px auto 15px; max-width: 80ch; max-width: 825px; background-color: #EEEEEE; padding: 30px 15px 15px; border-radius: 10px; color: #121212; width: 95%; box-sizing: border-box; }
      .section-accordion { & { background-color: #EEEEEE; box-shadow: none; font-size: 18px; line-height: 28px; }
        p a { color: #EA1C22; }
      }
      .section-accordion-summary.Mui-expanded { & { margin: 0 auto; min-height: 32px; }}
      .section-accordion-summary .MuiAccordionSummary-content { & { display: flex; flex-direction: column; margin: 2px 0; }
        p { margin: 2px 0; font-size: 18px; color: #121212; }
      }
      .section-accordion-summary .MuiAccordionSummary-content.Mui-expanded { & { margin: 0 auto; min-height: 32px; }
        p { margin: 0; text-align: left; }
      }
      .custom-accordion-control { & { cursor: pointer; color: #EA1C22; font-weight: 700; justify-content: center; align-items: center; margin: 10px auto 5px; }
        span { border-bottom: 1px solid #EA1C22; }
      }
      .up-arrow {
        & {  height: 16px; width: 16px; position: absolute; top: -16px;  margin-left: -6px; }
      }
      .link { & { color: #2D8D95; }
        a { font-weight: 600; color: #2D8D95; text-decoration: underline; }
      }
    }

    .score-section-container { 
      // & { grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(1, 1fr); display: grid; flex-direction: row; justify-content: space-between; align-items: center; max-width: 850px; padding: 15px 0 15px; margin: 0rem auto; width: 95%; }
      & { display: flex; flex-direction: row; justify-content: space-between; align-items: center; max-width: 825px; padding: 15px 0 15px; margin: 0rem auto; width: 95%; box-sizing: border-box; }
      .score-section { 
        & { display: flex ; flex-direction: column; flex: 0 1 25%; width: 185px; text-align: center; color: $textcolor; font-size: 18px; font-weight: 700; margin: 20px 10px 5px; text-transform: uppercase; align-items: center; justify-self: center; }
        .score-txt { font-size: 21px;line-height: 25px;font-weight: 700; color: #343333; font-family: $primaryfont; margin: 6px auto; max-width: fit-content; transition: all 0.4s linear; }
        .score-name { text-align: center; font-size: 18px; font-weight: 400; line-height: 22px; margin: 15px auto 10px; max-width: 135px; cursor: pointer; }
        .score-slider { & { width: 100%; margin: 0 auto; font-family: "Proxima-Nova", sans-serif; }
          .custom-bar { color: #352E6C; }
        }
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 10px; width: 100%; }
        .custom-bar .MuiLinearProgress-bar { background-color: #4298B5; }
      }
    }

    .invite-cta-btn { & { display: block; width: 100%; margin: 0px auto 15px; padding: 5px 15px 45px; color: #fff; border-radius: 5px; width: 100%; max-width: 280px; }
      a { display: block; text-decoration: none; color: #fff; }
      button { background-color: #EA1C22; width: 100%; max-width: 100%; display: inline-block; padding: 5px 25px; color: #fff; font-family: $primaryfont; font-size: 21px; font-weight: 700; height: 50px; border-radius: 5px; }
      button:hover { background-color: #FFF; transition: 0.3s; color: #EA1C22; border: 2px solid #EA1C22; }
    }

    .charts-accordions-container { & { width: 96%; max-width: 860px; margin: 15px auto 30px; box-sizing: border-box; padding: 10px 10px 30px; }
      .MuiAccordion-root { color: #FFFFFF; margin: 10px auto; }
      .MuiAccordionSummary-root { background-color: #435B63; color: #FFFFFF; }
      .MuiAccordionDetails-root { padding: 15px 15px 45px; }
      .MuiAccordionSummary-content { & { color: #FFFFFF; font-size: 18px; line-height: 31px; margin: 5px auto; }
        p { margin: 0 auto 0 0; text-align: left; }
      }
      .MuiAccordionSummary-expandIconWrapper { color: #FFFFFF; }
      .accordion-chart-container { padding: 0px; box-sizing: border-box; margin: 15px auto; }
    }

    .hurdles-enablers {
      & { width: 95%; max-width: 840px; margin: 15px auto 15px; }
      h2 { font-family: $primaryfont; font-weight: 700; font-size: 18px; line-height: 25px; text-align: left; margin: 15px auto; color: #202945; text-transform: uppercase; }
      .hurdles { background: #E5E5E5;  padding: 10px 4% 1px 2%; font-family: $primaryfont; font-size: 16px; line-height: 1.5em; color: $textcolor; text-align: left; border-radius: 13px; }
      .enablers { background: #E5E5E5;  padding: 10px 4% 1px 2%; font-family: $primaryfont; font-size: 16px; line-height: 1.5em; color: $textcolor; text-align: left; border-radius: 13px; }
      li { padding-bottom: 16px; font-weight: 400; font-size: 18px; }
    }
    // .urgency:last-child { padding-bottom: 75px; }
    .urgency {
      & { margin: 0 auto; padding: 1rem 0; width: 95%; max-width: 840px; }
      h2 { font-family: $primaryfont; font-weight: 700; font-size: 18px; line-height: 25px; text-align: center; margin: 15px auto 15px; }
      .urgency-slider { 
        & { position: relative; background-color: #F1813230; margin: 0 auto; padding: 115px 9% 80px; border-radius: 10px; }
        h3 { position: absolute; top: 0; color: #152036; font-weight: 600; font-size: 15px; left: 7%; }

        .slider-labels {
          & { width: 100%; font-weight: 400; font-size: 13px; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { margin-top: -20px; width: 100%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 9999; margin-left: -13px; margin-top: -12px; text-align: center; font-size: 18px; font-weight: bold; color: #fff; }
        .top-labels { position: absolute; width: 1px; height: 40px; top: -46px; margin-left: -2px; background-color: #F18132; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #152036; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background-color: #F18132; position: absolute; z-index: 9; }
        }
      }

      .custom-slider.MuiSlider-root { & { z-index: 1000; color: #F1813242; width: 100%; margin-left: 0px; }
        span.MuiSlider-track { left: -8px !important; }
      }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 8px solid #F1813260; height: 0; height: 2px; }

      .custom-slider .MuiSlider-thumb { background-color: #F18132; }
      .custom-slider .MuiSlider-thumb:before { background-color: #F18132; border: 4px solid #F18132; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider.individual .MuiSlider-thumb:before { background-color: #F18132; border: 4px solid #F18132; width: 24px; height: 24px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #152036; height: 2px; }
      .custom-bar .MuiLinearProgress-bar { background-color: #152036; }
    }
  }

  .check-up {
    & { background: #ffffff; border-radius: 20px; margin: 0 auto; max-width: 1000px; height: 450px; display: flex; align-items: center; justify-content: center; overflow: hidden; }
    .checkup-content {
      & { width: 52%; padding: 3rem 0 3rem 3rem; }
      h1 { font-size: 36px; font-weight: 700; color: #4A4A4A; margin-bottom: 40px; max-width: 20ch; }
      ol { margin: 0; padding: 0; padding-left: 24px; margin-bottom: 40px; }
      li { font-size: 22px; font-weight: 400; color: #4A4A4A; margin-bottom: 10px; }
      .cta-btn {
        & { width: 100%; max-width: 250px; background: #EA1C22; margin: 30px 0; color: #fff; border-radius: 5px; transition: 0.3s; border: 1px solid #EA1C22; }
        &:hover { background: #FFF; color: #EA1C22; transition: 0.3s; }
        a { display: block; text-decoration: none; color: #EA1C22; }
        a:hover { color: #EA1C22; }
        button { text-transform: uppercase;letter-spacing: 0; width: 100%; color: #fff; padding: 7px 5px; font-family: $primaryfont; font-size: 21px; border-radius: 3px; font-weight: 700; border: 1px solid #EA1C22; }
        button:hover { background-color: #FFF; color: #EA1C22; border: 1px solid #EA1C22; }
      }
    }
    .checkup-image {
      & { width: 49%; height: 100%; }
      img { width: 100%; height: 100%; object-fit: cover; }
    }
  }

  .results .charts-accordions-container .MuiAccordionDetails-root { padding: 0; }
  .results .charts-accordions-container .accordion-chart-container { margin: 15px auto 0; }
  

  // .testimonial-container {
  //   & { max-width: calc(880px - 4%); margin: 50px auto; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
  //   .avatar { margin-right: 53px; }
  //   .testimonial { 
  //     & { position: relative; color: #fff; font-family: 'lato', sans-serif; font-size: 21px; line-height: 1.35em; }
  //     .quotation-mark { position: absolute; margin-top: -50px; }
  //     .designation { 
  //       & { color: $color2; font-weight: 700; padding-top: 20px; }
  //       span {  font-weight: 400; } 
  //     }
  //   }
  // }
  .testimonial-container {
    // & { max-width: calc(880px - 4%); margin: 50px auto 0; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: $textcolor; display: flex; justify-content: center; }
    & {  margin: 30px auto 0; padding: 45px 2% 30px; font-family: $primaryfont; font-size: 24px; line-height: 36px; color: $textcolor; display: flex; justify-content: center; }
    
    .testimonial { 
      & { display: flex; flex-direction: column; position: relative; color: #ffffff; font-family: $primaryfont; font-size: 24px; line-height: 45px; text-align: center; }
      .testi { max-width: 64ch; text-align: center; color: #FFFFFF; font-family: $primaryfont; font-size: 19px; font-weight: 400; line-height: 1.2em; }
      .avatar {
        img { border-radius: 50%; padding: 10px; }
      }

      p { font-family: $primaryfont; font-style: normal; font-weight: 400; font-size: 24px; line-height: 32px;}
      .quotation-mark { & { display: block; text-align: left; font-size: 84px; color: $color1; margin-right: 15px; position: relative; line-height: 0; top: 10px; left: -5%; }
        img { height: 25px; width: auto; }
        // .quotation-block { position: absolute; right: 15px; top: -15px; }
      }
      .bottom { transform: scale(-1, -1); margin-left: 15px; text-align: left; top: -15px; left: 2%; }
      .designation { 
        & { text-align: center; color: #fff;font-family: $primaryfont; font-size: 32px; line-height: 38px; font-weight: 700; }
        span { font-weight: 300; font-style: normal; }
        .designation-inner { font-size: 18px; line-height: 22px; margin: 12px auto; font-weight: 600; }
      }
    }
  }

  .resources-container {
    & { margin: 40px auto; font-family: $primaryfont; font-size: 24px; display: flex; width: 96%; max-width: 1040px; background-color: #FFFFFF; border-radius: 10px; box-sizing: border-box; }
    .people-container { & { width: 50%; display: flex; justify-content: center; align-items: center; }
      .people-img { width: 100%; height: auto; }
  }

    .resources-details { & { width: 50%; }
      .details-header { & { display: flex; justify-content: center; margin: 20px auto; padding: 0 5%; } 
        h1 { font-weight: 700; font-size: 36px; color: #202945; line-height: 50px; }
    }
      .details-paragraphs { & { margin: 25px auto; font-weight: 400; font-size: 18px; color: #202945; padding: 0 5%; line-height: 30px; }
      p { max-width: 42ch; }
      .paragraph-2  { margin-top: 25px; }
    }

    .resources-btn-container { & { margin: 40px auto; padding: 0 5%; } 
      .cta-btn { background-color: #796BE1; border-radius: 5px; color: #fff; text-decoration: none; width: 100%; max-width: 275px; height: 50px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; transition: 0.3s }
      .cta-btn:hover { background-color: #B0AC24; transition: 0.3s; color: #fff; }
  }
 
  }
    
  }

  .send-invite-container { & { width: 95%; max-width: 650px; margin: 60px auto 10px; background-color: #FFFFFF; border-radius: 10px; font-family: $primaryfont; text-align: center; }
    .send-invite-header { & {  }  
      h1 { padding: 30px 15px 5px; font-weight: 700; font-size: 30px; line-height: 43px; color: #202945; }
    }

    .send-invite-paragraph { & { }
     .send-invite-line-1 { margin-bottom: 0; padding: 0 10px; }
     .send-invite-line-2 { margin-top: 2px; padding: 0 10px; }
     p { font-size: 21px; font-weight: 400; line-height: 25px; }
  }

   .send-invite-btn-container { & { padding-top: 15px; padding-bottom: 35px; } 
    .cta { background-color: #EA1C22; border-radius: 5px; color: #fff; text-decoration: none; width: 193px; height: 50px; text-align: center; font-family: $primaryfont; font-size: 21px; font-weight: 700; transition: 0.3s }
    .cta:hover { background-color: #B0AC24; transition: 0.3s; color: #fff; }
}



  }

  .testimonial-invite {
    & { font-family: $primaryfont; background-color: $textcolor; margin: 2rem; margin-top: 0; padding: 3rem; color: #fff; border-radius: 10px; font-size: 1.5rem; }
    p { max-width: 46ch; margin: 1rem auto 2rem auto; }
    .cta-btn { margin: 3rem auto 2rem auto; }
  }

  .schedule-container {
    & { max-width: calc(880px - 10%); background: #fff; margin: 20px auto; padding: 45px 5% 45px; font-family: $primaryfont; font-size: 21px; line-height: 32px; color: #122845; text-align: center; border-radius: 10px; }
    h3 { font-size: 36px; line-height: 10px; }
    .cta-btn {
      & { width: auto; display: inline-block; background: #2D8D95; margin: 40px auto 0; color: #fff; padding: 0; border-radius: 25px; }
      &:hover { background: $color2; color: $textcolor; transition: 0.3s; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: 220px; color: #fff; padding: 10px 0; font-family: $primaryfont; font-size: 18px; border-radius: 3px; font-weight: bold; }
    }
  }
  .invite-legacy-container {
    & { display: flex; justify-content: center; align-items: center; margin: 45px auto; }
    .invite-legacy { & { width: 100%; max-width: 1000px; box-sizing: border-box; background-color: #FFF; margin: 15px 25px; padding: 30px 15px 30px; font-family: $primaryfont; font-size: 24px; font-weight: 700; line-height: 36px; color: #202945; text-align: center; border-radius: 10px; }
      // p { margin: 0 auto; max-width: 45ch; padding: 0 30px; }
      h1 { font-size: 30px;line-height: 43px; }
      p { font-family: $primaryfont;line-height: 32px; max-width: 60ch; margin: 30px auto; font-weight: 400;font-size: 21px; padding: 0 2rem; }
      .cta-btn {
        & { width: 100%; max-width: 250px; background: #EA1C22; margin: 30px auto 30px; color: #fff; border-radius: 5px; transition: 0.3s; border: 1px solid #EA1C22; }
        &:hover { background: #FFF; color: #EA1C22; transition: 0.3s; }
        a { display: block; text-decoration: none; color: #EA1C22; }
        a:hover { color: #EA1C22; }
        button { text-transform: uppercase;letter-spacing: 0; width: 100%; color: #fff; padding: 7px 5px; font-family: $primaryfont; font-size: 21px; border-radius: 3px; font-weight: 700; border: 1px solid #EA1C22; }
        button:hover { background-color: #FFF; color: #EA1C22; border: 1px solid #EA1C22; }
      }
    }
  }

}

.btn { & { } }

footer { & { min-height: 100px; max-width: 1110px; margin: 0 auto; text-align: start; padding: 15px 30px 30px; box-sizing: border-box; }
  .bleat-logo img { height: 92px; width: auto; }
  .bleat-logo-max-padding img { height: 85px; width: 80px; padding: 10px 12px 100px 12px; }
}

.intro-container { & { height: 578px; width: 95%; max-width:1110px; text-align: center; margin: 26px auto 35px; max-width: 1110px; }
  .intro { min-height: 570px; max-width: 1110px; 
    background-image: url("../src/Assets/Images/intro.png"); background-size: cover; background-repeat: no-repeat; background-position: center; border-radius: 10px; box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.0945336);}
  .intro-heading { & { padding:28px 10px 10px; max-width: 719px; margin: 10px auto; }
    h1 { font-family: $primaryfont; text-align: center; font-weight:700; line-height: 40px; font-size: 32px;color:#343333; }
    span { color:#ED1B24; }
  }

  .intro-para { & { text-align: center;max-width: 668px;margin: 10px auto; }
    .para-one { font-weight: 400; font-size: 24px;line-height: 35px; color: #343333; }
    .para-two { font-weight: 400; font-size: 24px;line-height: 35px; }
  }
  .cta { & { text-align: center; }
    .cta-btn {
      & { display: block; margin: 45px auto 50px;padding: 5px 5px; text-decoration: none; width: 122px; height: 50px; background: #ED1B24; color: #fff; line-height: 25px; font-family: $primaryfont; border-radius: 5px;font-weight: 700; font-size: 21px; padding: 6px 0; text-align: center; text-transform: uppercase; }
      &:hover { background: #435B63; transition: 0.3s; color: #fff; }
    }
  }
}
  
  .label-name { font-size: 14px; font-weight: 600; margin: 2px auto; }
  .label-percent { font-size: 18px; font-weight: 700; margin: 2px auto; }
  
  .firstform-container { & { margin: 26px auto 80px;  border-radius: 10px; box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.0945336); width: 96%; max-width: 575px; min-height: 520px; background-color: #FFFFFF; }
  .firstform{padding-top: 48px;margin: 1px auto;text-align: center; 
    h1 { font-family: $primaryfont; font-weight:bold; font-size: 24px; line-height: 34px; color: #343333; padding: 0 15px; }
  }
  .form { & { text-align: center;width: max-content; margin: 1px auto; }
    .form-detail { & { margin: 1px auto;text-align: center; }
      .input-title { text-align: start; font-size: 16px;font-weight: 700; line-height: 19.2px; color: #3B3A3A; margin-bottom: 4px; }
      .form-details-text { width: 216px; height: 45px;font-family: $primaryfont; font-weight: 300; font-size: 18px; line-height: 21.6px;}
      .form-details-text .MuiOutlinedInput-input { font-family: $primaryfont; font-weight: 400; font-size: 18px; color: #343333; }
      .Mui-focused fieldset{ border: 2px solid #ED1B24; }
      // .MuiInputBase-root::placeholder { font-family: $primaryfont; color: #34333380; opacity: 1 !important; }
      .error-text { color: red; font-weight: 400; font-size: 13px;padding: 5px 3px;margin-top: 10px; max-width: 216px; text-align: left; }
      input::placeholder { font-weight: 400; color: #919191; opacity: 1 !important; } 
    }
    .attendance{ padding-top: 5px; max-width: 216px;}
    .attendance .MuiOutlinedInput-input::placeholder { font-family: $primaryfont; font-weight: 300; font-size: 18px; color: rgba(52, 51, 51, 0.5); }
    .zip{ padding-top: 10px; margin-top: 15px;}
    .zip .MuiOutlinedInput-input::placeholder { font-family: $primaryfont; font-weight: 300; font-size: 18px; color: rgba(52, 51, 51, 0.5); }
  }
  .cta{ & { text-align: center; padding-top:10px;}
    .cta-btn {
      & { margin:45px auto 5px auto; padding: 25px 5px; text-decoration: none; width: 216px; height: 50px; background: #ED1B24; color: #fff; line-height: 25px; font-family: $primaryfont; border-radius: 5px;font-weight: 700; font-size: 21px; padding: 6px 0; text-align: center; text-transform: uppercase; }
      &:hover { background: #435B63; transition: 0.3s; color: #fff; }
    }
  }

  .firstform-footer { & { width: 95%; margin: 1px auto; padding-bottom:5px; padding-top: 10px; }
    .firstform-footer-text{ max-width: 42ch; text-align: center; margin: 15px auto; font-weight: 400; font-size: 18px; line-height: 24px; padding-bottom: 5px; }
  }
}

  .chart-container { & { width: 96%; max-width: 1110px; border-radius: 10px; background-color: #FFFFFF; margin: 25px auto 5px; }
    .chart-heading { & { font-family: $primaryfont; margin: 25px auto 5px; justify-content: center; text-align: center; }
      h1 { padding: 30px 15px 0; font-weight: 600; font-size: 24px; line-height: 31px; color: #343333; margin: 15px auto 5px; max-width: 57ch; }
      h1.exception { font-weight: 600; }
      p { font-weight: 600; font-size: 24px; line-height: 31px; color: #334433; margin-top: 0; padding: 0 10px; }
      span { color: #EC1C24; font-weight: 700; }
    }

    // .chart-data{margin: 5px auto;}
    .step-buttons { & {width: 100%; display: flex;justify-content:space-between;margin: 5px auto; align-items: center; max-width: 85%; margin: 15px auto; padding: 30px 15px; }
    .cta-prev { padding: 11px 25px; color: #182036; font-size: 18px; font-weight: 400; font-family: $primaryfont; }
    // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }
    .cta {border: none; padding: 11px 25px; color: #182036; font-size: 18px; font-weight: 600; font-family: $primaryfont;
    }      
    .next-btn { border-bottom :1px solid #EC1C24; display: inline-block; }
    // .cta:hover { background: #435B63; transition: 0.3s; color: #fff; }
    // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
    // .nextbtn:hover { background-color: $color2; }
    // .nextbtn.Mui-disabled { background: #C1C1C1; }
    // .all-set-btn { font-size: 21px; padding: 7px 30px; background-color: #F18132; color: #FFFFFF; }
    }
  }

@media only screen and (min-width: 650px) {

  // main .assessment-additional .steps .slider-container .slider-labels br { content: ""; }
  // main .assessment-additional .steps .slider-container .slider-labels br:after { content: " "; }

}

@media only screen and (max-width: 1250px) {

  header {
    .logo-right { margin-left: 0; }
    // .bleat-logo { right: 50px; }
  }
 
 
  
  .custom-slider.MuiSlider-root { padding: 14px 0; }  

}

@media only screen and (max-width: 1110px) {
  
  main .dashboard .urgency { overflow: hidden; }
  main .testimonial-container .testimonial .testi { width: 90%; margin: auto;}
  main .testimonial-container .testimonial .testi br { content: ""; }
  main .testimonial-container .testimonial .testi br:after { content: " "; }
  
  main .start .intro {
    h1 { font-size: 32px; line-height: 44px; text-align: left; }
    .intro-points-list { font-size: 18px; }
    
    .completion { margin-top: 15px; }
    
    
  }
  
}

@media only screen and (max-width: 950px) {
  main .resources-container { & { flex-direction: column; }
    .people-container { & { width: 100%; max-height: 400px; overflow: hidden; align-items: flex-start; border-radius: 10px 10px 0 0; }
      img { object-fit: cover; object-position: top; }
    }
    .resources-details { & { width: 100%; }
      .details-header h1 { text-align: center; max-width: 28ch; }
      .details-paragraphs p { margin: 0 auto; text-align: center; }
      .resources-btn-container { text-align: center; }
    }
  }
}


@media only screen and (min-width: 831px) {
  // main .bleat-logo-mobile { display: none; }
  main .container.start { margin: 30px auto; }

  
}

@media only screen and (max-width: 830px) {

  header { & { height: auto; // flex-direction: column; align-items: flex-start; 
    }
    .header-wrapper { display: grid; grid-template-columns: 0.25fr 1fr 0.25fr; height: 85px; padding: 10px 15px; box-sizing: border-box; }
    .left-logo img { width: 49px; height: 49px; }
    .logo-right { min-width: 50px; }
    // .logo-right { position: relative; margin: 0 auto; left: 0; margin: 20px auto; padding: 0 0 0 10px; width: auto; min-width: auto; }
    // .assessment-title { margin: 10px auto; 
    //   .logo-text { font-size: 28px; line-height: 42px; }
    //   .logo-subtext { font-size: 20px; line-height: 25px; letter-spacing: 4px; }
    // }
    // .bleat-logo { display: none; }
    .assessment-title { margin: 10px auto; 
      .logo-text { font-size: 28px; line-height: 30px; }
      .logo-subtext { font-size: 20px; line-height: 21px; letter-spacing: 4px; }
    }
  }
  main .current-section { font-size: 16px; margin: 10px auto 30px; }
  main .section-progress-top { margin: 25px auto 15px; }
  main .progress-percent-text { margin: 7px auto; }

  main .results .charts-accordions-container, main .dashboard .charts-accordions-container {
    padding: 0 0 45px;
  }
  .label-name { font-size: 14px; margin: 2px auto; }
  .label-percent { font-size: 16px; margin: 2px auto; }
  
  // main .invite .form { width: 75%; }
  main .invite .invite-another-person .invite-another { top: 40px; top: 85px; height: 32px; width: 72px; right: -26px; left: 0; margin-left: 0%; }
  main .invite .cta-btn { margin-right: 21%; margin: 0; }

  footer { & { min-height: 0; width: 100%; left: 0; }
  .bleat-logo{
    &{display:flex; justify-content: center;}
  }
    .bleat-logo-mobile { & { width: 100%; display: flex; justify-content: center; min-height: 70px; }
      img { height: 42px; width: auto; }
    }
  }
  
  main {
    .container { margin-bottom: 15px; }
    .testimonial-container .testimonial { font-size: 21px; flex-direction: column; }
    .invite-legacy-container .invite-legacy { & { margin-bottom: 20px; font-size: 21px; margin: 1rem 1rem; padding: 0; }
      h1 { font-size: 26px; }
        p { font-size: 18px; }
    }
    .container.start { margin: 15px auto; background-color: #00252A; }
    .start {
      & { flex-direction: column; height: auto; width: 95%; }
      .video { & { width: 100%; box-sizing: border-box; height: auto; border-radius: 10px; }
        .custom-controls { max-width: 90%; height: 30px; top: 90%; }
        .playbtn { top: 75%; top: 82%; }
      }
      .intro { 
        & { background-color: #FFFFFF; border-radius: 10px; width: 100%; box-sizing: border-box; margin: 0 auto; padding: 15px; height: auto; }
        h1 { text-align: center; width: auto; font-size: 28px; margin: 0 auto 15px; max-width: 26ch; padding-top: 30px; }
        h2 { text-align: center; width: 100%; max-width: 34ch; margin: auto; }
        .intro-para { text-align: center; max-width: 35ch; margin: 5px auto; font-size: 21px; }
        .intro-points-list { width: auto; max-width: 34ch; margin: 15px auto; padding-left: 20px; }
        .completion { 
          & { flex-direction: column; margin: 30px auto 0 auto; }
          .time-estimate { margin: 15px auto; }
          .cta { margin-bottom: 0; }          
        }
      }
    }
    .results .result-text, .dashboard .result-text { width: 95%; box-sizing: border-box; font-size: 20px; }
    .thank-you {
      h1 { font-size: 18px;line-height: 30px; }
      p { font-size: 16px; }
    }
  }
  main .logo-left-container{display: none;}
  main .start .video video { width: 100%; } //margin: 0 -10%; object-fit: cover; }
  main .start .video .thumbnail { width: 100%; height: 100%; object-fit: cover; }
  main .assessment .steps {
    .progress-percent-text { display: none; }
    .question { font-size: 21px; }
    .progress-bar { 
      // & { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1rem; }
      & { display: flex; justify-content: center; }
      .bar-section { width: 90% !important; }
    }
    .current-section { margin: 10px auto 0; }
    .progress-indicator { margin: 50px auto; }
    .question { align-items: flex-start; }
    .slider-container .slider-labels { & { font-size: 16px; }
      .label { max-width: 10ch; }
    }
    .radio-container .slider-labels { & { font-size: 16px; }
      // .label { max-width: 10ch; }
    }
  }
  main .assessment-additional .steps {
    h1 { margin: 15px auto 15px; }
    .progress-indicator { margin: 15px auto 10px; }
    .slider-container .slider-labels .label p { & { font-size: 16px; line-height: 21px; } }
    .radio-container .slider-labels .label p { & { font-size: 16px; text-align: left; margin: 5px auto 5px 0; } }
  }
  main .assessment-additional .steps .slider-container .slider-labels { font-size: 16px; }
  main .details .form, main .pre-additional-questions-details .form { width: 85%; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 16px; }
  main .results .selection-form { & { font-size: 28px; }
    .form-details-text .MuiInput-root { font-size: 28px; }
  }
  main .dashboard {
    table { & { font-size: 15px; }
      thead th { font-size: 14px; }
    }
  }

  main .results, main .dashboard {
    .si_ass_progress .msi_score { font-size: 24px; }
  }
  
  .intro-container { & {}
    .intro { padding: 30px 15px; min-height: unset; height: 453px; background-image: url("../src/Assets/Images/intro-bg-mobile.png"); }
    .intro-heading { & { max-width: 519px; margin:10px auto; }
      h1 { line-height: 35px;font-size: 28px; }
    }
    .intro-para { & { max-width: 468px;margin: 10px auto; }
      .para-one{ font-size: 20px;line-height: 30px; }
      .para-two{ font-size: 20px;line-height: 30px; }
    }
    .cta {
      .cta-btn { & {line-height: 22px;font-size: 18px;}
      // &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    }
  }

  
  .chart-container { & {}
    .chart-heading{ & { font-family: $primaryfont; margin: 25px auto 5px; justify-content: center; text-align: center;}
      h1 { padding-top: 25px; font-weight: 600; font-size: 21px; line-height: 31px; color: #343333; }
      p { font-weight: 600; font-size: 21px; line-height: 31px; color: #343333; padding: 0 10px; }
      span { color:#EC1C24; font-weight: 700; }
    }

  // .chart-data{margin: 5px auto;}

    .step-buttons { & { width: 100%; display: flex; justify-content:space-between; margin: 25px auto; align-items: center; max-width: 85%; padding: 10px 25px 15px; }
    .cta-prev { padding: 11px 25px; color: #182036; font-size: 16px; font-weight: 400; font-family: $primaryfont; }

    // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }

    .cta {
      & { max-height: 50px; background-color: #ED1824; font-weight: 700; font-size: 21px;line-height: 25.2px; color: #FFFFFF; padding: 11px 18px; }
      &:hover { background: #435B63;transition: 0.3s; color: #fff; }
      }
      .next-btn{border-bottom: none;}

    // .cta:hover { background: #435B63; transition: 0.3s; color: #fff; }
    // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
    // .nextbtn:hover { background-color: $color2; }
    // .nextbtn.Mui-disabled { background: #C1C1C1; }
    // .all-set-btn { font-size: 21px; padding: 7px 30px; background-color: #F18132; color: #FFFFFF; }
    }
  }

  main .check-up { flex-direction: column-reverse; height: 750px; max-width: 95%; width: 95%; }
  main .check-up .checkup-content { height: 50%; width: 90%; padding: 1rem 0; }
  main .check-up .checkup-content h1 { text-align: center; font-size: 26px; }
  main .check-up .checkup-content li { font-size: 18px; }
  main .check-up .checkup-content .cta-btn { margin: 30px auto; margin-bottom: 50px; }
  main .check-up .checkup-image { height: 50%; width: 100%; }
}

@media only screen and (max-width: 1000px) and (min-width: 831px) {
  main .start .intro {
    h1 { font-size: 28px; line-height: 40px; text-align: left; }
    .intro-para { font-size: 22px; line-height: 30px; }
    .intro-points-list { font-size: 18px; }
    
    .completion { margin-top: 15px; }
    .completion .cta { font-size: 18px; max-width: 200px; }
  
  
  }


}

@media only screen and (max-width:1065px) {
  main .results .score-section-container, main .dashboard .score-section-container {
    // grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(1, 1fr);
    display: flex; justify-content: space-between; align-items: center; flex-direction: row; flex-wrap: wrap;
  }
}

@media only screen and (min-width: 650px) {

  // main .dashboard .urgency .urgency-slider .slider-labels .label p br { content: ""; }
  // main .dashboard .urgency .urgency-slider .slider-labels .label p br:after { content: " "; }

}

@media only screen and (max-width: 650px) {

  header { 
    .header-wrapper { display: grid; grid-template-columns: 0.15fr 1fr 0.15fr; padding: 10px 15px; }
    // .header-wrapper { display: grid; grid-template-columns: 80px 1fr 80px; height: auto; }
    .logo-left { & { text-align: center; } img { height: 52px; width: auto; max-width: 60px; }}
    .assessment-title { & { margin: 7px auto; }
      img { height: auto; }
      .logo-text { font-size: 24px; line-height: 28px }
      .logo-subtext { font-size: 18px; line-height: 25px; }}
      .left-logo img { width: 39px; height:39px; }
  }

  main .assessment .steps, main .assessment-additional .steps { width: 90%; padding: 0 5%; min-height: 400px; margin: 0 auto 30px; }
  // main .start .video .playbtn { width: 144px; height: 144px; }
  main .start { & {}
    .video .playbtn { width: 210px; height: auto; top: 85%; }
    .intro {
      h1 { text-align: center; }
      h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
      // h1 br { content: ""; }
      h1 br:after { content: " "; }
    }
    .para-container p { font-size: 18px; }
  }
  main .assessment .mobile-spacer { display: none; }
  main .assessment .steps .progress-bar {
    .bar-section .progress-percentage { font-size: 1rem; margin: 1rem 0 0.5rem 0.75rem; }
    .sections .section-name { font-size: 15px; }
  }
  main .pre-additional-info { & { padding: 15px; } h1 { font-size: 21px; } p { font-size: 18px; } }
  main .details, main .pre-additional-questions-details {
    .form { & { width: 90%; } 
    .form-detail { & { flex: 0 1 100%; max-width: 100%; }
      .input-title { max-width: 100%; }
      .error-text { margin: 0 auto -10px; }
    }
    }
  }

  main .results, main .dashboard {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .progress-bar { width: 85%; position: relative; margin: 75px auto 5px; max-height: 90px; }
    .si_ass_progress { height: 11px; }
    .progress-opacity-filter { height: 11px; bottom: -11px; }
    .pervalue { font-size: 13px; bottom: 50px; }
    .si_pro_header { bottom: 67px; }
    .pervalue-text { & { bottom: 70px; margin: 4px auto; position: relative; width: 100%; }
      p { display: inline-block; text-align: center; margin: 0 auto; font-size: 13px; line-height: 13px; color: #4A4A4A; }
    }
    .si_ass_progress .score-arrow { width: 14px; height: 14px; margin-left: -7px; bottom: 30px; }
    .si_ass_progress .msi_score { font-size: 21px; padding: 2px 4px; text-align: center; min-width: 4ch; border-radius: 4px; bottom: 35px; }
    .result-sections .score-section { font-weight: 400; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: -15px; }

    .section-bottom-text .up-arrow { display: none; }
  }
  main .results .si_ass_progress .progress-line { bottom: 0; }
  main .results, main .dashboard { & {  }
    .result-text { font-size: 18px; padding: 15px 18px; margin: 40px auto 20px; }
    .score-section-container {
      // & {grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr);flex-wrap: wrap; padding: 0 0 20px 0; max-width: 90%; margin-top: 0; }
      & { grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr); }
      .score-section { 
        & { margin: 15px auto; width: 100%; max-width: 260px; }
        .score-txt { font-size: 18px; font-weight: 700; margin: 7px auto; }
        .score-name { font-size: 17px; font-weight: 400; min-width: 24ch; margin: 0.25rem auto; }
        .score-slider { width: 90%; margin: 0 auto 7px; max-width: 250px; }
        
      }
    }
    .members-answered .section { text-transform: uppercase; font-weight: 400; }
  }
  main .invite-container { & { font-size: 1.1rem; /* color: white; */ margin: 0 auto; }
    .invite-container-spacer { padding: 1rem; 
      h3 { font-size: 24px; line-height: 43px; margin: 1.5rem auto; padding: 0 15px; }
      p { font-size: 21px; max-width: 25ch; }
      .invite-reasons { & { flex-wrap: wrap; padding: 20px 0 20px; }
        .reason { & { flex: 0 1 100%; font-size: 21px; line-height: 28px; max-width: 27ch; margin: 15px auto; }
          .height30 { height: 15px; }
        }
        .reason span { /* color: $color1; */ }
        .reason img { } 
      }
    }
  }
  main .testimonial-container .testimonial .quotation-mark img { height: 18px; width: auto; }
  main .testimonial-container .testimonial { & { color: #fff; margin: 20px auto 0; text-align: center; }
    p { font-size: 20px; line-height: 32px; }
    .quotation-mark { color: $color1; font-size: 46px; }
    .testi { font-size: 18px; }
    .designation { color: #FFF; 
      span { color: #fff; font-size: 18px; }
    }
  }
  main .details h1, main .pre-additional-questions-details h1 { font-size: 28px; }
  main .testimonial-invite {
    & { background-color: #DCDBDB; margin: 0 auto; padding: 3rem 2rem; color: #27282A; border-radius: 10px; font-size: 21px; font-weight: 700; }
    p { max-width: 40ch; margin: 1rem auto; line-height: 43px; }
    .cta-btn { margin: 2rem auto 0rem auto; }
  }
  main .results .members-answered { & { margin: 15px auto; }
    .accordion-container { & { background-color: #755075; }
      .accordion-title { color: #FFFFFF; text-align: left; margin: 2px auto 2px 0; text-transform: uppercase; font-weight: 700; font-size: 18px; }
      .accordion-expand-icon { color: #FFFFFF; }
      .accordion-content { background-color: #FFFFFF; padding: 0; }
      .accordion-summary { & { min-height: 48px; }
        .Mui-expanded { margin: 5px auto; }
      }
      .inner-accordion-container { & { background-color: #FFFFFF; color: #000000; margin: 0 auto; }
        .inner-accordion-summary { & { margin: 0 auto; min-height: 30px; padding: 5px 15px 5px; border-top: 1px solid black; }
          .MuiAccordionSummary-content { margin: 0 auto; }
          .Mui-expanded { margin: 0 auto; min-height: 24px; }
        }
        .inner-accordion-title { & {color: #000000; text-align: left; margin: 2px auto 2px 0; text-transform: uppercase; font-size: 15px; font-weight: 700; }
          .true { color: #73B76F; }
          .neutral { color: #FFB545; }
          .false { color: #FF6645; }
        }
        .inner-accordion-expand-icon { color: #755075; }
        .inner-accordion-content { background-color: #FFFFFF; font-size: 16px; border-top: 1px solid black; }
      }
    }
  }
  main .dashboard {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .alignment .tab-buttons button { font-size: 16px; padding: 10px 12px; background-color: #97979733; }
    .alignment .content .align-details .answered-detail .more-details .more-row { 
      & { justify-content: space-between; }
      .answer { font-size: 14px; }
    }
    .alignment { & { width: 95%; }
      .container-tabs { background-color: #F2F2F2; }
      .tab-buttons { & { flex-wrap: wrap; padding: 10px; box-sizing: border-box; background-color: #F2F2F2; }
        button { & { font-size: 15px; min-width: 55px; padding: 10px 5px; flex: 0 1 47%; border-radius: 3px; color: #343333; }
          &.active { color: #FFFFFF; background-color: #755075; }
        }
      }
      .tabs-content { background-color: #F2F2F2; padding: 0 10px 10px; }
      .content .align-details .answered-detail:nth-child(even) { background-color: #FBFBFB; }
      .content .align-details .answered-detail .answer { font-size: 13px; line-height: 16px; font-weight: 400; align-self: center; }
    }
  }
  main .dashboard .alignment .content .align-details .aligned-tabs {
    & { margin: 0 auto; border-top: 1px solid gray; }
    .MuiAccordionSummary-root.Mui-expanded { min-height: 48px; }
    .MuiAccordionSummary-content.Mui-expanded { margin: 0 auto; }
    .MuiCollapse-root.MuiCollapse-entered { border-top: 1px solid gray; }
    .MuiAccordionDetails-root { padding: 10px 15px; }
    .expand-icon { color: #1EA74A; }
    .agree { color: #73B76F; }
    .sagree { color: #2B7A27; }
    .neutral { color: #FDBF04; }
    .disagree { color: #F17E7E; }
    .sdisagree { color: #EA1C22; }
  }
  main .invite-legacy-container .invite-legacy { & { padding: 3rem 1rem; }
    .cta-btn { margin: 2rem auto 0 auto; max-width: 95%; width: 100%; }
  }
  main .resources-container {
    .people-container { max-height: 300px; }
    .resources-details .details-header h1 { font-size: 24px; line-height: 28px; }
  }

  .chart-container {
    & { margin: 15px auto; width: 95%; }
    .chart-heading h1 { font-weight: 600; }
    .chart-heading h1.exception { font-weight: 600; }
    // .chart-data { margin: 5px auto; }
    .step-buttons {
      & { padding: 1px 25px 15px; margin: 1px auto; }
      .cta-prev { padding: 5px 25px;margin-bottom: 15px; }
      .cta { margin-bottom: 15px; }
      // .cta { margin: 0; font-size: 16px; color: #182036; padding: 11px 25px; font-family: $primaryfont; }
      // .cta:hover { background: #435B63; transition: 0.3s; color: #fff; }
      // .nextbtn { background: $color1; color: #fff; padding: 14px 26px; border-radius: 35px; font-weight: 700; }
      // .nextbtn:hover { background-color: $color2; }
      // .nextbtn.Mui-disabled { background: #C1C1C1; }
      // .all-set-btn { font-size: 21px; padding: 7px 30px; background-color: #F18132; color: #FFFFFF; }
    }
  }

}


@media only screen and (max-width: 550px) {
  
  header .assessment-title {
    .logo-text { font-size: 21px; line-height: 25px; font-weight: 600; white-space: nowrap; }
    .logo-subtext { font-size: 16px; line-height: 18px; font-weight: 400; }
  }
  main .start { margin-top: 15px; }
  main .start .video { & { height: 57vw; width: 100%; border-radius: 10px; }
    video { width: auto; }
    .close-video { right: 5px !important; top: 5px !important; }
  }
  main .start .intro { & {  }
    h1 { font-size: 21px; line-height: 25px; max-width: 26ch; margin: 0 auto 15px; font-weight: 700; padding-top: 5px; }
    h2 { font-size: 17px; max-width: 34ch; }
    .intro-para { font-size: 18px; line-height: 24px; max-width: 34ch; }
    .intro-points-list { font-size: 17px; font-weight: 300; line-height: 37px; max-width: 36ch; padding-left: 5px; }
    .completion img { margin: 0 4px 0 0; }
  }
   
  main .assessment { margin-top: 15px; min-height: 440px; }
  main .assessment .steps {
    .question { min-height: 150px !important; font-size: 18px; line-height: 24px; max-width: 100%; display: flex; justify-content: center; align-items: center; padding-bottom: 20px; }
    .progress-bar .bar-section .section-title { font-size: 12px; }
    .slider-container { margin: 20px auto 90px; }
    .radio-container { margin: 20px auto 42px; min-height: auto; }
    .slider-container .slider-labels .label { width: 14%; }
    .progress-indicator { margin: 15px auto 5px; font-size: 18px; }
    .question p { font-size: 21px; }
    .progress-bar { grid-gap: 0; }
    .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
    .progress-bar .bar-section .section-title { font-size: 12px; }
    .slider-container .slider-labels { font-size: 14px; }
    .slider-container .slider-labels .label p.desktop { display: none; }
    .slider-container .slider-labels .label p.mobile { display: block; }
    .radio-container .slider-labels { font-size: 12px; }
    .radio-container .slider-labels .label p.desktop { display: none; }
    .radio-container .slider-labels .label p.mobile { display: block; }
    .step-buttons { margin: 30px auto 15px; padding-top: 15px; padding-bottom: 30px; }
    .step-buttons button {padding: 12.5px 2px; }
  }

  .chart-container .chart-heading p { max-width: 36ch; margin: 0px auto 5px; }
  
  main .assessment-additional { & { margin-top: 15px; }
    .steps .textarea-field { width: 100%; }
    .steps .form-details-text { height: auto; min-height: 120px; }
    .steps .question { font-size: 18px; line-height: 24px; padding: 0 0 5px; }
    .steps .question p br { content: ""; }
    .steps .question p br:after { content: " "; }
    .steps .slider-container .slider-labels { font-size: 14px; }
    .steps .step-buttons { padding-bottom: 30px; }
    .step-buttons .all-set-btn { font-size: 18px; padding: 9px 15px; }
  }

  main .pre-additional-info .step-buttons, main .pre-additional-questions-details .step-buttons, main .assessment-additional .step-buttons { & { padding: 5px 15px 5px; max-width: 100%; width: 100%; margin: 10px auto; }
    button, .next-btn { padding: 9px 10px; }
    .skip-btn { margin: 0 0 30px auto; }
  }
  main .assessment-additional .steps { margin: 0 auto 15px; display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: 500px; }

  // main .assessment .steps .slider-container .custom-slider.MuiSlider-root { width: calc(100% + 25px); margin-left: -6px; }
  main .assessment .steps .slider-container .slider-labels .label p { width: 145%; margin-left: -75%; letter-spacing: 0px; line-height: 18px; }
  main .assessment-additional .steps .slider-container .slider-labels .label p { line-height: 18px; }

  main .details { & { width: 95%; margin-top: 15px; }
    h1, main .pre-additional-questions-details h1 { font-size: 24px; }
    p { padding: 0 5%; font-size: 18px; }
    p br { content: ""; }
    p br:after { content: " "; }
    .form, main .pre-additional-questions-details .form { width: 90%; }
    .form .form-detail .form-details-text { padding-right: 0; max-width: 100%; }
    .details-buttons { & { max-width: 90%; display: flex; flex-direction: column-reverse; }
      .back-btn { align-self: flex-start; margin-top: 2rem; }
      .cta-btn { & { width: 100%; max-width: 280px; margin: 0 auto; text-align: center; }
        button { width: 100%; max-width: 280px; padding: 8px 1.5rem; } }
    }
  }
  // main .details .form .form-detail .form-details-text.react-select { max-width: 97.5%; }

  main .results { width: 95%; margin-top: 15px; }
  main .results .selection-form { width: max-content; max-width: 95%; flex-wrap: wrap; }
  main .results .selection-form .form-detail { width: 100%; margin-right: 0; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; font-size: 18px; margin-top: 8px; }
  main .results h1 { font-size: 24px; line-height: 27px; padding: 1.5rem 1rem 1rem; width: auto; }
  main {
    .results .result-text, .dashboard .result-text { & { margin: 40px auto 20px; font-size: 18px; line-height: 24px; }
      p { margin: 6px auto; }
    }
  }
  main .results .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .results .result-sections .score-section { flex: 0 1 100%; margin-bottom: 20px; }
  main .invite-container .cta-btn { & { width: 100%; max-width: 90%; } 
    button { font-size: 18px; padding: 8px 16px; line-height: 16px; width: 100%; }}
  main .results .result-sections .score-section .score-txt { margin-bottom: 9px; }
  main .results .progress-bar, main .dashboard .progress-bar { & { width: 85%; } }
  main .results .members-answered { width: 95%; font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }
  main .results .score-section-container { & { grid-template-columns: repeat(1, 1fr);grid-template-rows: repeat(1, 1fr); }
    .score-section { width: 100%; max-width: 90%; }
  }

  main .send-invite-container { & { margin: 30px auto 15px; }
    .send-invite-header h1 { font-size: 21px; line-height: 28px; }
    .send-invite-paragraph p { font-size: 18px; line-height: 32px; }
    .send-invite-btn-container { & { width: 100%; box-sizing: border-box; padding: 10px 10px 30px; }
      .cta { width: 100%; max-width: 90%; }
    }
  }

  main .invite-container { max-width: 95%; font-size: 18px; padding: 0 15px 30px; }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite-container .invite-container-spacer h3 { font-size: 21px; line-height: 28px; }
  main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
  main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }

  main .team-container { width: 90%; font-size: 18px; }
  main .team-container h3 { font-size: 24px; line-height: 38px; }

  main .schedule-container { width: 85%; font-size: 18px; }
  main .schedule-container h3 { font-size: 24px; }
  // main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  // main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }

  main .testimonial-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonial-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonial-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonial-container .testimonial .designation { & { font-size: 21px; }
    .designation-inner { font-weight: 400; margin: 2px auto; }
  }
  main .testimonial-container .testimonial .designation span { padding-top: 6px; }

  main .assessment-additional .steps .textarea { min-height: 120px; }
  main .assessment-additional .steps .textarea-field { margin-bottom: -15px; }
  main .invite { width: 95%; margin-top: 15px; }
  main .invite h1 { font-size: 24px; width: auto; }
  main .invite h1 br { content: ""; }
  main .invite h1 br:after { content: " "; }
  main .invite p { margin: 5px 2% 10px; font-size: 18px; }
  main .invite p br { content: ""; }
  main .invite p br:after { content: " "; }
  main .invite .form { width: 75%; }
  main .invite .form .form-detail { flex: 0 1 100%; }
  main .invite .form .form-detail .invite-field { max-width: 100%; }
  main .invite .form .form-detail .error-text { bottom: auto; }
  main .invite .form .invite-number { left: -25px; top: 11px; font-size: 18px; font-weight: 400; display: flex; align-items: center; justify-content: center; }
  main .invite .form .delete-btn { right: auto; left: -32px; top: 45px; top: 55px; }
  main .invite-legacy-container .invite-legacy { & { font-size: 21px; margin: 15px 12px; }
    p { padding: 0 15px; }
  }

  main .invite .invite-another-person .invite-another { & { top: 30px; height: 32px; width: 72px; right: -26px; left: 0; margin-left: -18%; }
    .add-btn { font-size: 18px; }
  }
  main .invite .cta-btn { width: 164px; min-width: 160px; padding: 4px 0; width: auto; padding: 4px 15px; font-size: 20px; font-size: 18px; }
  main .invite .invite-notification-settings { & { width: 80%; }
    .notification-checkbox { align-self: center; }
  }
  main .thank-you { width: 95%; }
  main .thank-you h1 { font-size: 24px; max-width: 30ch; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 17px; }
  main .testimonial-container .testimonial { font-size: 18px; width: 90%; margin: 0px auto 0; text-align: center; }

  
  main .MuiSwitch-thumb { background-color: #EA1C22 !important; position: relative; }
  main .MuiSwitch-thumb::after { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); content: ""; width: 3px; height: 3px; border: 3px solid #FFFFFF; border-radius: 50%; padding: 2px; background-color: transparent !important; }
  main .MuiSwitch-track { background-color: #E7E9EB !important; opacity: 1 !important; }
  main .individual-group { display: none; }
  main .toggle-individual-group { display: block; margin: 15px auto; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 21px; }
  main .toggle-individual-group .left { flex: 0 1 25%; text-align: right; color: #ffffff; }
  main .toggle-individual-group .right { flex: 0 1 35%; color: #ffffff; }
  main .toggle-individual-group .active { font-weight: 700; color: #EA1C22; color: #ffffff; }
  main .dashboard { width: 95%; margin-top: 15px; }
  main .dashboard h1 { font-size: 24px; padding: 1.5rem 1rem 1rem; width: auto; }
  main .dashboard .mobile_scores { display: block; }
  main .dashboard .result-sections { margin: 50px auto; padding-bottom: 0; }
  main .dashboard .result-sections .score-section { flex: 0 1 50%; }
  main .dashboard .result-sections .score-section .score-txt { margin: 10px auto; }
  main .dashboard .result-sections .score-section .score-name { font-weight: 400; margin: 10px auto 0; }
  main .dashboard hr { display: none; }
  main .dashboard table { font-size: 13px; display: none; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }
  main .dashboard .urgency h2 { text-align: center; }
  main .dashboard .urgency .urgency-slider h3 { text-align: center; left: 0; width: 100%; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 12px; }
  main .dashboard .most-answered { font-size: 16px; }
  main .dashboard .most-answered .most-answered-detail { font-size: 15px; padding: 10px 15px; width: 100%; box-sizing: border-box; }
  main .dashboard .alignment .content .align-details .answered-detail { & { font-size: 16px; }
    .more-details { border-left: 3px solid #ED8F14; }
  }
  main .dashboard, main .results { 
    .hurdles-enablers ul { & { padding-left: 30px; } }
    li { font-size: 16px; }
  }
  main .dashboard .alignment .content .align-details { & { font-size: 16px; }
    .answered-detail p { flex: 0 1 90%; }
    .answered-detail .more-toggle { width: 24px; min-width: 24px; padding: 0; align-self: center; color: #ED8F14; }
  }
  main .results .selection-form .form-details-text .MuiInput-root { font-size: 24px; }
  main .dashboard .alignment .content .align-details.analyze .answered-detail {  padding: 10px 15px 10px 5px; }
  main .dashboard .alignment .content .align-details.analyze .answered-detail .more-info {  padding-left: 10px; }
  main .results, main .dashboard {
    .hurdles-enablers {
      h2 { font-size: 16px; text-align: center; }
      li { font-size: 16px; word-break: break-word; }
    }
    .urgency {
      h2 { font-size: 16px; }
      .custom-bar .MuiLinearProgress-bar { background-color: #F1813230; }
      .urgency-slider { margin: 0; background-color: #F1813230; }
      .urgency-slider.individual { padding-top: 45px !important; }
      .custom-slider.MuiSlider-root { color: transparent; }
      .custom-slider .MuiSlider-track { border: 0px; }
      .MuiLinearProgress-root.custom-bar { background-color: #F1813230; height: 8px; margin-top: -22px; border-radius: 5px; width: calc(100% + 3px); margin-left: -2px; }
      .slider-points .point { width: 8px; height: 8px; margin-top: -2px; margin-left: -5px; }
    }
  }

  
  .firstform-container { & { margin: 26px auto 80px; width: 95%; min-height: 525px; }
    .firstform { & { padding-top: 20px; margin: 1px auto; }
      h1 { font-weight:700; font-size: 21px; line-height: 38px;padding: 5px; }
    }
    .cta{
      .cta-btn {
        & {margin:25px auto 5px auto; padding: 25px 5px; text-decoration: none; width: 216px; height: 50px; background: #ED1B24; color: #fff; line-height: 25px; font-family: $primaryfont; border-radius: 5px;font-weight: 700; font-size: 21px; padding: 6px 0; text-align: center; text-transform: uppercase; }
        &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    }
    .firstform-footer { & { max-width: 40ch; text-align: center; padding: 5px 5px 10px 5px; }
      .firstform-footer-text { font-weight: 400; font-size: 15px; line-height: 24px; margin-top: 40px; }
    }
  }

}

@media only screen and (max-width: 550px) {

  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 14px; margin-bottom: 31px; }  
  main .start .intro .completion { & { margin: 30px auto 0; width: 100%; justify-content: center; }
    .time-estimate { margin-bottom: 0; }
  }
  main .start .intro .completion .cta { margin: 0 auto; width: 100%; max-width: 95%; }
  .intro-container {
    .intro-heading { max-width: 32ch; margin: 10px auto; padding: 15px 15px 0;
      h1 { line-height: 25px; font-size: 21px; }
    }
    
    .intro-para { max-width: 30ch; margin: 10px auto;
      .para-one{ font-size: 18px; line-height: 22px; margin: 0 auto 10px; }
      .para-two{ font-size: 18px; line-height: 22px; }
    }
    .cta {
      .cta-btn { & { line-height: 25px; font-size: 21px; width: auto; margin: 75px auto 45px; padding: 7px 15px; }
        // &:hover { background: #435B63; transition: 0.3s; color: #fff; }
      }
    }
  }
  main .resources-container .people-container { max-height: 235px; }

  .MuiLinearProgress-root.custom-bar { height: 7px; border-radius: 10px; }
  main .assessment .steps .slider-container .slider-points .point{ height: 7px !important; width: 7px !important; margin-top: 0; } 
  main .assessment .steps .slider-container .slider-points{ width: 122%; margin-left: 8px; }
}

@media only screen and (max-width: 450px) {
 
  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }
  .slider-points {
    & { margin-top: -20px; width: 122% !important; font-weight: 300; font-size: 14px; color: #343333; position: relative; margin-left: 7px; }
  }
}

// Tabs CSS


// .tabs {
//   padding: 15px 0px;
//   text-align: center;
//   color: white;
//   width: 100%;
  
//   cursor: pointer;

//   box-sizing: content-box;
//   position: relative;
//   outline: none;
// }
// .active-tabs {
//   color: #000;
//   background: $color1;
//   border-bottom: 1px solid transparent;
//   &::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: calc(100%);
//     height: 0px;
//     background: rgb(88, 147, 241);
//   }
// }
// button {
//   border: none;
// }

